import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardText,
  CardImg,
  Label,
  FormGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import {
  RadialBar,
  RadialBarChart,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import Select from 'react-select';
import s from './Cards.module.scss';
import { push } from 'connected-react-router';
import lifestyleImg from '../../../assets/cards/lifestyleImg.png';
import leavesImg from '../../../assets/cards/leavesImg.png';
import coupleImg from '../../../assets/cards/coupleImg.png';
import orangeEyesImg from '../../../assets/cards/orangesEyesImg.png';
import girlImg from '../../../assets/cards/girlImg.png';
import cerealsImg from '../../../assets/cards/cerealsImg.png';
import mariaImage from '../../../assets/navbarMenus/mariaImage.jpg';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { GET_USER, API_TIMEOUT } from '../../../utils';
import axios from 'axios';
import Widget from '../../../components/Widget/Widget';

export default function Cards() {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState();
  const [dataSelect, setDataSelect] = useState([]);
  const [userId, setUserId] = useState('');
  const [simpleDropdownValue, setSimpleDropdownValue] = useState('');
  const [tanggalTampil, setTanggalTampil] = useState();

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);

    const curAnak = dataSelect.filter((item) => {
      return item.label == select;
    });

    localStorage.setItem('idUser', curAnak[0].value);
    setUserId(curAnak[0].value);
    // setCurrentAnak(curAnak[0]);
  };

  const radialChartStyle = {
    top: '50%',
    left: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };

  const radialBar = {
    data: [
      {
        name: 'target',
        uv: 100.0,
        pv: 2400,
        fill: '#2F33A7',
      },
      {
        name: 'makan pagi',
        uv: 18.69,
        pv: 4567,
        fill: '#4d53e0',
      },
      {
        name: 'makan siang',
        uv: 20.69,
        pv: 1398,
        fill: '#00A5FF',
      },
      {
        name: 'pmt',
        uv: 22.22,
        pv: 9800,
        fill: '#43BC13',
      },
      {
        name: 'snack',
        uv: 24.63,
        pv: 3908,
        fill: '#FF5668',
      },
      {
        name: 'makan malam',
        uv: 26.63,
        pv: 4800,
        fill: '#FFA100',
      },
      {
        name: 'total',
        uv: 90.02,
        pv: 4800,
        fill: '#FFC405',
      },
    ],
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
    }
    const tgl = new Date();
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;
    setTanggalTampil(`${day}-${month}-${year}`);
  }, []);

  useEffect(() => {
    const uid = currentUser ? currentUser.uid : null;
    if (uid) ambilUser();
  }, [currentUser]);

  const [uid, setUid] = useState();

  const ambilUser = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: currentUser.uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const dt = response.data.data;

          if (dt.length > 0) {
            let dtk = dt.map((item) => {
              return { value: item.id, label: item.nama, rating: item.berat };
            });

            setDataSelect(dtk);
            setUid(dtk[0].value);
            setSimpleDropdownValue(dtk[0].label);
            localStorage.setItem('idUser', dtk[0].value);
          } else {
            ambilUserDummy();
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilUserDummy = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: 'xxxxxx',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const dt = response.data.data;
          if (dt.length > 0) {
            let dtk = dt.map((item) => {
              return { value: item.id, label: item.nama, rating: item.berat };
            });

            setDataSelect(dtk);
            setSimpleDropdownValue(dtk[0].label);
            localStorage.setItem('idUser', dtk[0].value);
            setUid(dtk[0].value);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  function toggleTab(tabId) {
    setActiveTab(tabId);
  }

  return (
    <div>
      <Row>
        <Col>
          <Row className='gutter mb-4'>
            <Col xs={12} lg={6}>
              <div className='headline-1 text-default mb-1'>Asupan Harian</div>
              <div
                className={`body-3 text-dark-gray ${s.titleCardDescription}`}
              >
                Pilih user untuk menginput asupan makan harian. Pemberian makan
                dicatat berdasarkan menu makan yang dikonsumsi
              </div>

              <FormGroup row>
                <Label md='4' for='default-select'>
                  Pilih Anak (sample)
                </Label>
                <Col md='8' className={s.select}>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle caret color='primary'>
                      {simpleDropdownValue}
                    </DropdownToggle>

                    <DropdownMenu>
                      {dataSelect &&
                        dataSelect.map((item) => (
                          <DropdownItem onClick={changeSelectDropdownSimple}>
                            {item.label}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Col>
              </FormGroup>
            </Col>
            <Col xs={12} lg={6}>
              <div className={s.rechartsBlock}>
                <div className='headline-2 mb-2'>
                  Kecukupan kalori hari ini <b> {tanggalTampil}</b>
                </div>
                <Row>
                  <Button
                    className='btn mt-3'
                    color='primary'
                    onClick={() => {
                      if (!uid) {
                        alert('pilih anak terlebih dahulu');
                      } else {
                        dispatch(push(`/template/makan/asupan/makan_pagi`));
                      }
                    }}
                  >
                    Makan Pagi
                  </Button>
                </Row>
                <Row>
                  <Button
                    className='btn mt-3'
                    color='warning'
                    onClick={() => {
                      if (!uid) {
                        alert('pilih anak terlebih dahulu');
                      } else {
                        dispatch(push(`/template/makan/asupan/makan_siang`));
                      }
                    }}
                  >
                    Makan Siang
                  </Button>
                </Row>
                <Row>
                  <Button
                    className='btn mt-3'
                    color='success'
                    onClick={() => {
                      if (!uid) {
                        alert('pilih anak terlebih dahulu');
                      } else {
                        dispatch(push(`/template/makan/asupan/snack`));
                      }
                    }}
                  >
                    Snack
                  </Button>
                </Row>
                <Row>
                  <Button
                    className='btn mt-3'
                    color='secondary-red'
                    onClick={() => {
                      if (!uid) {
                        alert('pilih anak terlebih dahulu');
                      } else {
                        dispatch(push(`/template/makan/asupan/makan_malam`));
                      }
                    }}
                  >
                    Makan Malam
                  </Button>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
