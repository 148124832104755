import React, { useState, useEffect } from "react";
import FIREBASE from "../../../config/FIREBASE";
//import FIREBASE from '../config/FIREBASE';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import * as dataFormat from "./UsersDataFormatters";
import actions from "../../../actions/usersListActions";
import axios from "axios";
import swal from "sweetalert";
import {
  ADMIN_LOGIN,
  API_TIMEOUT,
  GET_DESA,
  GET_KECAMATAN,
  LIST_ADMIN_KG,
} from "../../../utils";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  Col,
  Form,
  Row,
  Badge,
  Label,
  FormGroup,
  Input,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import Widget from "../../Widget/Widget";

import s from "../Users.module.scss";
import { useDispatch } from "react-redux";

//import { logApp } from '../../../utils/func';

const { SearchBar } = Search;

const AdminListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);

  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idHapus, setIdHapus] = useState();

  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("2023");
  const [bantuan, setBantuan] = useState([]);
  const [tahun, setTahun] = useState("2023");
  const [jumlahPenerimaBantuan, setJumlahPenerimaBantuan] = useState(0);
  const [nama, setNama] = useState("");
  const [idAdmin, setIdAdmin] = useState("");

  const [status, setStatus] = useState("");
  const [username, setUsername] = useState("");
  const [dinas, setDinas] = useState("");
  const [url, setUrl] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [cari, setCari] = useState(false);
  const [proses, setProses] = useState(false);

  const [edit, setEdit] = useState(false);
  const [tambah, setTambah] = useState(false);
  const [gambar, setGambar] = useState("");
  const [uid, setUid] = useState("");

  const [modalDelete, setModalDelete] = useState(false);
  const [modalDelete2, setModalDelete2] = useState(false);
  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
  ];

  const [kecamatan, setKecamatan] = useState("");
  const [desa, setDesa] = useState("");
  const [listDesa, setListDesa] = useState([]);
  const [listDesaPilihan, setListDesaPilihan] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);

  const dispatchKu = useDispatch();

  const ubahKecamatan = (kecamatan) => {
    var listDesaPilih = listDesa.filter((item) => {
      return item.kecamatan == kecamatan;
    });

    var listDesaPilihFilter = listDesaPilih.map((item, index) => {
      return { id: index, label: item.desa, value: item.desa };
    });

    setListDesaPilihan(listDesaPilihFilter);

    setKecamatan(kecamatan);
  };

  const ubahKecamatan2 = (kecamatan, desa) => {
    if (kecamatan && desa) {
      var listDesaPilih = listDesa.filter((item) => {
        return item.kecamatan == kecamatan;
      });

      var listDesaPilihFilter = listDesaPilih.map((item, index) => {
        return { id: index, label: item.desa, value: item.desa };
      });
      setKecamatan(kecamatan);
      setListDesaPilihan(listDesaPilihFilter);
      setDesa(desa);
    }
  };

  const getListDesa = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_DESA);
      data1 = response.data.data;

      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListKecamatan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_KECAMATAN);
      data1 = response.data.data;

      if (data1.length > 0) {
        var dataku = data1.map((item, index) => {
          return { id: index, label: item.kecamatan, value: item.kecamatan };
        });
        setListKecamatan(dataku);
      } else {
        setListKecamatan([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserEmail = (email) => {
    FIREBASE.database()
      .ref("users")
      .orderByChild("email")
      .equalTo(email)
      .once("value", (querySnapshot) => {
        //hasil disini

        let data = querySnapshot.val() ? querySnapshot.val() : null;

        let arrayOfNames = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const nama = data[key];
            arrayOfNames.push(nama);
          }
        }

        if (data) {
          checkUser(arrayOfNames[0].uid);

          setNama(arrayOfNames[0].nama);
          setUsername(arrayOfNames[0].email);
          setStatus("");
          setDinas(arrayOfNames[0].unitKerja);
          setUrl(arrayOfNames[0].avatar);
          setUid(arrayOfNames[0].uid);
          convertGambar(arrayOfNames[0].avatar);
        } else {
          setCari(false);
          swal("Gagal", "Username tidak ditemukan", "error");
        }
      })
      .catch((error) => {
        setCari(false);
        swal("Gagal", "Username tidak ditemukan", "error");
      });
  };

  const cariUser = (email) => {
    setCari(true);
    getUserEmail(email);
  };

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listTahun.filter((item) => {
      return item.tahun == select;
    });
    // ambilBantuan(curAnak[0].tahun);
    setTahun(curAnak[0].tahun);
  };

  useEffect(() => {
    getListDesa();
    getListKecamatan();
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      //  logApp(currentUser.uid, 'List Admin');
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      // ambilBantuan(tahun);
      ambilListAdmin();
    }
  }, [currentUser]);

  const checkUser = (uid) => {
    axios({
      method: "post",
      url: ADMIN_LOGIN,
      timeout: API_TIMEOUT,
      data: { uid: uid },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 0) {
            setCari(false);
            setNama("");
            setUsername("");
            setStatus("");
            setDinas("");
            setUrl("");
            setUid("");
            convertGambar("");

            return swal(
              "Info",
              "Akun ini sudah terdaftar dalam aplikasi Kalkukator Gizi - Pantau PMT",
              "info"
            );
          } else {
            setCari(false);
          }

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        setCari(false);
        setNama("");
        setUsername("");
        setStatus("");
        setDinas("");
        setUrl("");
        setUid("");
        convertGambar("");
        swal("Error", "Terjadi kesalahan ", "error");
      });
  };

  const ambilListAdmin = () => {
    axios({
      method: "get",
      url: LIST_ADMIN_KG,
      timeout: API_TIMEOUT,
      data: {
        bantuan: bantuan,
        tahun: tahun,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          setJumlahPenerimaBantuan(data.length);

          setRowsKu(data);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const handleSimpan = () => {
    if (edit) {
      closeModal();
    } else if (tambah) {
      if (!nama || !username || !status) {
        swal("Gagal", "Data yang anda masukan harus lengkap", "error");
        return;
      } else if (status == "user") {
        if (!desa) {
          swal("Gagal", "Data desa dari user harus diisi", "error");
          return;
        }
      } else if (status != "user") {
        if (!dinas) {
          swal("Gagal", "Data Dinas dari admin harus diisi", "error");
          return;
        }
      }

      setProses(true);
      simpanAdmin();
    }
  };

  const handleDelete = () => {
    setProses(true);
    deleteAdmin();
  };

  const openModal3 = (cell) => {
    const id = cell;
    //  getDataPenerimabantuan(cell);
    setIdAdmin(id);

    const admin = rowsKu.filter((item) => {
      return item.id == id;
    });
    const adminKu = admin[0];

    setNama(adminKu.nama);

    setModalDelete2(true);
  };

  const closeModal2 = () => {
    setEdit(false);
    setTambah(false);
    setNama("");
    setUsername("");
    setStatus("");
    setDinas("");
    setUrl("");
    setSearchItem("");
    setGambar("");
    setIdAdmin("");

    setModalDelete2(false);
  };

  const deleteAdmin = async () => {
    const response = await axios.delete(
      "https://kalkulatorgizi.rotendaokab.go.id/api/postadmin/" + idAdmin,

      {
        //credentials: 'include',
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   Authorization: `Bearer ${accessToken}`,
        // },
      }
    );

    if (response.data.success === 1) {
      swal("Sukses", "Data Admin " + nama + " berhasil dihapus", "success");
      ambilListAdmin();
      setProses(false);
      closeModal2();
    } else {
    }
  };

  const simpanAdmin = async () => {
    const formData = new FormData();
    formData.append("photo", gambar);

    formData.append("uid", uid);
    formData.append("dinas", status == "user" ? desa : dinas);
    formData.append("username", username);
    formData.append("status", status);

    formData.append("nama", nama);

    const response = await axios.post(
      "https://kalkulatorgizi.rotendaokab.go.id/api/postadmin",
      formData,
      {
        //credentials: 'include',
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        //   Authorization: `Bearer ${accessToken}`,
        // },
      }
    );

    if (response.data.success === 1) {
      setProses(false);
      swal("Sukses", "Data Admin " + nama + " berhasil ditambahkan", "success");

      closeModal();
      ambilListAdmin();
    }
  };

  const convertGambar = (avatar) => {
    // Data gambar dalam format data URI
    if (avatar) {
      const imageDataURI = avatar; // Sisipkan data gambar lengkap di sini

      // Memisahkan data URI menjadi tipe dan base64 encoded data
      const [dataType, base64Data] = imageDataURI.split(",");

      // Mengubah base64 encoded data menjadi bentuk binary
      const binaryData = atob(base64Data);

      // Mendapatkan panjang data binary
      const len = binaryData.length;

      // Membuat array untuk menyimpan data binary dalam bentuk Uint8Array
      const uint8Array = new Uint8Array(len);

      // Mengisi array dengan data binary
      for (let i = 0; i < len; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Membuat file baru dari Uint8Array dan menambahkan tipe datanya
      const file = new File([uint8Array], "gambar.jpg", { type: dataType });

      setGambar(file);
    } else {
      setGambar("");
    }
  };

  const openModal = (cell) => {
    const id = cell;
    //  getDataPenerimabantuan(cell);
    setIdAdmin(id);

    const admin = rowsKu.filter((item) => {
      return item.id == id;
    });
    const adminKu = admin[0];

    setNama(adminKu.nama);
    setUsername(adminKu.username);
    setStatus(adminKu.status);
    setDinas(adminKu.dinas);
    setUrl(adminKu.profile);

    setModalDelete(true);
  };

  const openModal2 = () => {
    setTambah(true);

    setModalDelete(true);
  };

  const closeModal = () => {
    setEdit(false);
    setTambah(false);
    setNama("");
    setUsername("");
    setStatus("");
    setDinas("");
    setUrl("");
    setSearchItem("");
    setGambar("");
    setCari(false);

    setModalDelete(false);
  };

  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        {currentUser ? (
          currentUser.statuskalgizi == "super admin" ? (
            <>
              <Button
                className={`${s.controlBtn} mx-2`}
                color="primary"
                size="sm"
                onClick={() => {
                  setEdit(true);
                  openModal(cell);
                }}
              >
                Edit
              </Button>
              <Button
                className={s.controlBtn}
                color="danger"
                size="sm"
                onClick={() => {
                  openModal3(cell);
                }}
              >
                Delete
              </Button>
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const statusFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color={
            cell == "admin"
              ? "secondary-red"
              : cell == "super admin"
              ? "warning"
              : "success"
          }
          size="xs"
          onClick={() => {
            "";
          }}
        >
          {cell}
        </Button>
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: "avatar",
      sort: true,
      formatter: dataFormat.imageFormatter,
      text: "Avatar",
    },
    {
      dataField: "nama",
      sort: true,
      text: "Nama",
    },
    {
      dataField: "dinas",
      sort: true,
      text: "Dinas",
    },
    {
      dataField: "username",
      sort: true,
      text: "Username",
    },

    {
      dataField: "status",
      sort: true,
      formatter: statusFormatter,
      text: "Status",
    },
    {
      dataField: "id",
      formatter: actionFormatter,
      text: "Actions",
    },
  ];

  return (
    <div>
      <Widget className="widget-p-md">
        <Row>
          <Col
            className="pr-grid-col"
            xs={4}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <code> Jumlah Admin </code>{" "}
            <Badge style={{ marginLeft: 5 }} color="danger">
              {jumlahPenerimaBantuan}
            </Badge>
          </Col>
          <Col className="pr-grid-col" xs={2}>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="primary">
                {simpleDropdownValue2}
              </DropdownToggle>

              <DropdownMenu>
                {listTahun &&
                  listTahun.map((item) => (
                    <DropdownItem onClick={changeSelectDropdownSimple2}>
                      {item.tahun}
                    </DropdownItem>
                  ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
          {currentUser ? (
            currentUser.statuskalgizi == "super admin" ? (
              <Col
                className="pr-grid-col"
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="success"
                  className="btn"
                  size="sm"
                  onClick={() => openModal2()}
                >
                  Tambah Admin
                </Button>
              </Col>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Row>
        <ToolkitProvider columns={columns} data={rowsKu} keyField="id" search>
          {(props) => (
            <React.Fragment>
              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? "table-responsive" : ""
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size="md" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Admin Tulu Fali</ModalHeader>
        <ModalBody>
          <FormGroup>
            <CardBody>
              {tambah && (
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="text"
                      id="search-input1"
                      value={searchItem}
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                      {cari ? (
                        <Button color="default" disabled>
                          <Spinner size="sm" color="light" /> Loading
                        </Button>
                      ) : (
                        <Button
                          color="default"
                          onClick={() => cariUser(searchItem)}
                        >
                          Search
                        </Button>
                      )}
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              )}
              {url && (
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={url ? url : ""}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 50,

                      marginBottom: 10,
                      border: "2px solid blue",
                    }}
                  />
                </Row>
              )}
              {nama && (
                <Form onSubmit={(e) => ""}>
                  <FormGroup row>
                    <Label md={3} for="normal-field" className="text-md-right">
                      Nama
                    </Label>
                    <Col md={9}>
                      <Input
                        type="text"
                        id="default-textarea"
                        placeholder="nama"
                        value={nama}
                        onChange={(e) => setNama(e.target.value)}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} for="normal-field" className="text-md-right">
                      Username
                    </Label>
                    <Col md={9}>
                      <Input
                        type="text"
                        id="default-textarea"
                        placeholder="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md={3} for="normal-field" className="text-md-right">
                      Peran
                    </Label>
                    <Col md={9}>
                      <Input
                        type="select"
                        id="normal-field"
                        placeholder=""
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        required
                      >
                        <option value={""}>- Pilih peran -</option>
                        <option value={"user"}>user</option>
                        <option value={"admin"}>admin</option>
                        <option value={"super admin"}>super admin</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  {status == "user" ? (
                    <>
                      <FormGroup row>
                        <Label
                          md={3}
                          for="normal-field"
                          className="text-md-right"
                        >
                          Kecamatan
                        </Label>
                        <Col md={9}>
                          <Input
                            type="select"
                            id="normal-field"
                            placeholder="gender"
                            value={kecamatan}
                            onChange={(e) => ubahKecamatan(e.target.value)}
                          >
                            <option value={""}> pilih Kecamatan</option>
                            {listKecamatan &&
                              listKecamatan.map((item, index) => (
                                <option value={item.label} key={index}>
                                  {item.label}
                                </option>
                              ))}
                          </Input>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          md={3}
                          for="normal-field"
                          className="text-md-right"
                        >
                          Desa
                        </Label>
                        <Col md={9}>
                          <Input
                            type="select"
                            id="normal-field"
                            placeholder="desa"
                            value={desa}
                            onChange={(e) => setDesa(e.target.value)}
                          >
                            <option value={""}> pilih Desa</option>
                            {listDesaPilihan &&
                              listDesaPilihan.map((item, index) => (
                                <option value={item.label} key={index}>
                                  {item.label}
                                </option>
                              ))}
                          </Input>
                        </Col>
                      </FormGroup>
                    </>
                  ) : (
                    <FormGroup row>
                      <Label
                        md={3}
                        for="normal-field"
                        className="text-md-right"
                      >
                        Dinas
                      </Label>
                      <Col md={9}>
                        <Input
                          type="text"
                          id="normal-field"
                          placeholder=""
                          value={dinas}
                          onChange={(e) => setDinas(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  )}
                </Form>
              )}
            </CardBody>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          {proses ? (
            <Button color="default" disabled>
              <Spinner size="sm" color="light" /> Kirim
            </Button>
          ) : (
            <Button color="primary" onClick={() => handleSimpan()}>
              {tambah ? "Tambah" : "Simpan"}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalDelete2} toggle={() => closeModal2()}>
        <ModalHeader toggle={() => closeModal2()}>Hapus Admin</ModalHeader>
        <ModalBody>
          <FormGroup>
            <CardBody>
              {"Apakah anda yakin ingin menghapus admin atas nama "}
              <code> {nama}</code>
              {" ?"}
            </CardBody>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={() => closeModal2()}>
            Cancel
          </Button>
          {proses ? (
            <Button
              size={modalDelete2 ? "sm" : "md"}
              color={modalDelete2 ? "default" : "danger"}
              disabled
            >
              <Spinner size="sm" color="light" />{" "}
              {modalDelete2 ? "proses" : "Kirim"}
            </Button>
          ) : (
            <Button size="sm" color="danger" onClick={() => handleDelete()}>
              {"Hapus"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(AdminListTable);
