import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as dataFormat from './UsersDataFormatters';
import actions from '../../../actions/usersListActions';
import axios from 'axios';
import { GET_USER, API_TIMEOUT } from '../../../utils';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from 'reactstrap';
import CustomModal from '../../../components/CustomModal/CustomModal';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Widget from '../../Widget/Widget';

import s from '../Users.module.scss';
import { useDispatch } from 'react-redux';

const { SearchBar } = Search;

const UsersListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [rowsTable, setRowsTable] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idHapus, setIdHapus] = useState();

  const [modalDelete, setModalDelete] = useState(false);

  const dispatchKu = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
    }
  }, []);
  useEffect(() => {
    const uid = currentUser ? currentUser.uid : null;
    if (uid) ambilUser();
  }, [currentUser]);

  const ambilUser = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: currentUser.uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          if (response.data.data.length > 0) {
            const data = response.data.data;
            data.map((item, index) => {
              const umur = hitungUmur(item.dob);
              data[index].dob = umur + ' .b';
            });

            setRowsKu(data);
          } else {
            ambilUserDummy();
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const ambilUserDummy = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: 'xxxxxx',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          if (response.data.data.length > 0) {
            const data = response.data.data;
            data.map((item, index) => {
              const umur = hitungUmur(item.dob);
              data[index].dob = umur + ' .b';
            });

            setRowsKu(data);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
    return totalMonthDiff;
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: GET_USER + '/' + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          alert('Data anak berhasil dihapus');
          const idToDelete = idHapus;
          const filteredArray = rowsKu.filter((obj) => obj.id !== idToDelete);
          setRowsKu(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;

    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };
  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color='info'
          size='xs'
          onClick={() => props.dispatch(push(`/admin/users/${cell}`))}
        >
          View
        </Button>
        <Button
          className={`${s.controlBtn} mx-2`}
          color='success'
          size='xs'
          onClick={() => props.dispatch(push(`/admin/users/${cell}/edit`))}
        >
          Edit
        </Button>
        <Button
          className={s.controlBtn}
          color='danger'
          size='xs'
          onClick={() => {
            openModal(cell);
          }}
        >
          Delete
        </Button>
      </div>
    );
  };

  const floatFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        {parseFloat(cell).toFixed(0)}
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: 'avatars',
      sort: true,
      formatter: dataFormat.imageFormatter,
      text: 'Avatar',
    },
    {
      dataField: 'nama',
      sort: true,
      text: 'nama',
    },
    {
      dataField: 'dob',
      sort: true,
      text: 'Umur (bulan)',
    },
    {
      dataField: 'gender',
      sort: true,
      text: 'gender',
    },
    {
      dataField: 'berat',
      sort: true,
      formatter: floatFormatter,
      text: 'berat',
    },
    {
      dataField: 'tinggi',
      sort: true,
      formatter: floatFormatter,
      text: 'tinggi',
    },
    {
      dataField: 'lila',
      sort: true,
      formatter: floatFormatter,
      text: 'lila',
    },
    {
      dataField: 'catataKesehatan',
      sort: true,

      text: 'catatan',
    },
    {
      dataField: 'id',
      formatter: actionFormatter,
      text: 'Actions',
    },
  ];

  return (
    <div>
      <Widget className='widget-p-md'>
        <Button
          color='primary'
          className='btn-rounded'
          onClick={() => dispatchKu(push(`/admin/users/new`))}
        >
          Tambah Anak
        </Button>
        <ToolkitProvider columns={columns} data={rowsKu} keyField='id' search>
          {(props) => (
            <React.Fragment>
              <SearchBar
                className='react-bootstrap-table-search-input my-3'
                {...props.searchProps}
              />

              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? 'table-responsive' : ''
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size='sm' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi hapus</ModalHeader>
        <ModalBody>apakah anda yakin akan menghapus data anak ini?</ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(UsersListTable);
