import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import Widget from '../../../components/Widget/Widget.js';
import {
  Form,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  Button,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  Progress,
  DropdownMenu,
  Spinner,
} from 'reactstrap';
import StarIcon from '../../../assets/badges/StarIcon.js';
import {
  RadialBar,
  RadialBarChart,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import swal from 'sweetalert';

import DatePicker from 'react-datepicker';
import s from './Lists.module.scss';

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import {
  GET_ASUPAN,
  API_TIMEOUT,
  POST_MAKAN,
  DELETE_MAKAN,
  GET_MAKAN,
  GET_USER,
  GET_KEBUTUHAN_KALORI,
  GET_KONSUMSI_KALORI_KATEGORI,
  GET_KONSUMSI_KALORI_HARI,
  POST_MAKAN_PMT,
} from '../../../utils/index.js';

export default function PMT() {
  const [list, setList] = useState([]);
  const [listTampil, setListTampil] = useState([]);
  const [keb, setKeb] = useState(1000);
  const [kebutuhanKal, setKebutuhanKal] = useState(null);
  const [konsumsiKaloriHari, setKonsumsiKaloriHari] = useState(null);
  const [umur, setUmur] = useState();
  const tgl = new Date();
  const year = tgl.getFullYear();
  const month = String(tgl.getMonth() + 1).padStart(2, '0');
  const day = String(tgl.getDate()).padStart(2, '0');
  const date_string = `${year}-${month}-${day}`;
  const [idAnak, setIdAnak] = useState('');

  const [tanggal, setTanggal] = useState(date_string);

  const [simpleDropdownValue, setSimpleDropdownValue] = useState('');

  const [savingProses, setSavingProses] = useState(false);

  const [urlFile1, setUrlFile1] = useState('');
  const [fotoAnak, setFotoAnak] = useState('');

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);

    const curAnak = listAnaks.filter((item) => {
      return item.label == select;
    });

    setIdAnak(curAnak[0].value);
    hitungUmur(curAnak[0].dob);
    // setCurrentAnak(curAnak[0]);
  };

  const makan = 'ajald_adfjalf';
  let kategoriKu = makan.split('_');

  if (kategoriKu.length == 1) kategoriKu = kategoriKu[0];
  if (kategoriKu.length == 2) kategoriKu = kategoriKu[0] + ' ' + kategoriKu[1];

  const [kategori, setKategori] = useState('PMT');
  const [kaloriKategori, setKaloriKategori] = useState({
    pagi: undefined,
    siang: undefined,
    malam: undefined,
    snack: undefined,
    pmt: undefined,
    totai: 0,
  });

  const decreaseJumlah = () => {
    setJumlah((prevJumlah) => {
      const newJumlah = prevJumlah - 0.25;
      return newJumlah > 0 ? newJumlah : prevJumlah; // Never go below 0.25
    });
  };

  const increaseJumlah = () => {
    setJumlah((prevJumlah) => prevJumlah + 0.25); // Increase by 0.25
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFotoAnak(e.target.files[0]);
  }
  const resetJumlah = () => {
    setJumlah(0.25); // Reset to the minimum increment value
  };
  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
    setUmur(totalMonthDiff);
    ambilKebutuhanKalori(totalMonthDiff);
  };

  const ambilKonsumsiKaloriHari = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI_HARI,
      timeout: API_TIMEOUT,
      data: {
        tanggal: date_string,
        uid: idAnak,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setKonsumsiKaloriHari(response.data.data[0]);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKonsumsiKategori = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI_KATEGORI,
      timeout: API_TIMEOUT,
      data: {
        tanggal: date_string,
        uid: idAnak,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          let data = response.data.data;
          //Fungsi untuk mencari data berdasarkan properti
          function findByProperty(array, property, value) {
            return array.find((item) => item[property] === value);
          }

          // Contoh penggunaan
          const pagi = findByProperty(data, 'kategori', 'makan pagi');
          const siang = findByProperty(data, 'kategori', 'makan siang');
          const malam = findByProperty(data, 'kategori', 'makan malam');
          const snack = findByProperty(data, 'kategori', 'snack');
          const pmt = findByProperty(data, 'kategori', 'pmt');

          const d1 = pagi ? (pagi.total_energy ? pagi.total_energy : 0) : 0;
          const d2 = siang ? (siang.total_energy ? siang.total_energy : 0) : 0;
          const d3 = malam ? (malam.total_energy ? malam.total_energy : 0) : 0;
          const d4 = snack ? (snack.total_energy ? snack.total_energy : 0) : 0;

          const d5 = pmt ? (pmt.total_energy ? pmt.total_energy : 0) : 0;
          let total = d1 + d2 + d3 + d4 + d5;
          total =
            total != 0
              ? kebutuhanKal
                ? ((total / kebutuhanKal.kalori) * 100).toFixed(2)
                : 0
              : 0;

          const dataKtgr = {
            pagi: pagi,
            siang: siang,
            malam: malam,
            snack: snack,
            pmt: pmt,
            total: total,
          };
          setKaloriKategori(dataKtgr);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKebutuhanKalori = (age) => {
    axios({
      method: 'post',
      url: GET_KEBUTUHAN_KALORI,
      timeout: API_TIMEOUT,
      data: {
        usia: age,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setKebutuhanKal(response.data.data[0]);
          const kebutuhanKal = response.data.data[0];

          ambilKonsumsiKategori(kebutuhanKal);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const containerStyle = {
    width: '100%',
    height: '300px', // Atur tinggi sesuai kebutuhan Anda
    overflow: 'auto',
    /* Gaya tampilan scroll */
    scrollbarWidth: 'thin',
    scrollbarColor: '#888888 #f0f0f0',
  };

  const [listSelected, setListSelected] = useState([]);
  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [jumlah, setJumlah] = useState(0);
  const [listAnaks, setListAnaks] = useState([]);
  const [currentUser, setCurrentUser] = useState('');

  const [uid, setUid] = useState();
  const [nama, setNama] = useState('');

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));

      setCurrentUser(currentUser);
      ambilUser(currentUser.uid);
    }
  }, []);

  useEffect(() => {
    ambilAsupanMakanan();
  }, []);

  useEffect(() => {
    if (idAnak) {
      handleAmbilMakan();
    }
    if (kebutuhanKal) {
      ambilKonsumsiKategori();
      ambilKonsumsiKaloriHari();
    }
  }, [idAnak, tanggal, kebutuhanKal]);

  useEffect(() => {
    setListTampil(list);
  }, [list]);

  const ambilUser = (uid) => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const dt = response.data.data;

          if (dt.length > 0) {
            let dtk = dt.map((item) => {
              return {
                value: item.id,
                label: item.nama,
                rating: item.berat,
                dob: item.dob,
              };
            });

            setListAnaks(dtk);
            hitungUmur(dtk[0].dob);
            setIdAnak(dtk[0].value);
            setSimpleDropdownValue(dtk[0].label);
          } else {
            // ambilUserDummy();
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilAsupanMakanan = () => {
    axios({
      method: 'get',
      url: GET_ASUPAN,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: '',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const res = response.data.data;
          const fil = res.filter((item) => {
            return item.sumber == 'Ya';
          });
          setList(fil);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const selectAction = (item) => {
    setSelected(item);
    setModalOpen(true);
  };

  const handleSimpan = () => {
    if (jumlah < 0.25) {
      swal('Info', 'jumlah harus lebih besar dari 0', 'info');
    } else if (!fotoAnak) {
      swal('Info', 'Foto anak sementara makan wajib diisi', 'info');
    } else {
      setSavingProses(true);
      handleSimpanDB();
    }
  };

  const searchItems = (e) => {
    const src = e.target.value;
    if (src == '') {
      setListTampil(list);
    } else {
      const filteredItems = list.filter((item) =>
        item.asupan.toLowerCase().includes(src.toLowerCase())
      );

      setListTampil(filteredItems);
    }
  };

  const handleSimpanDB = async () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    const dataKu = {
      tanggal: date_string,
      kategori: kategori,
      itemMakanan: selected.id,
      uid: idAnak,
      waktu: '08:09:30',
      jumlah: jumlah,
    };

    const formData = new FormData();
    formData.append('tanggal', date_string);
    formData.append('kategori', kategori);
    formData.append('itemMakanan', selected.id);
    formData.append('uid', idAnak);
    formData.append('waktu', '08:09:30');
    formData.append('jumlah', jumlah);
    formData.append('photo', fotoAnak);

    const response = await axios.post(POST_MAKAN_PMT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success === 1) {
      setSavingProses(false);
      handleAmbilMakan();
      ambilKonsumsiKategori();
      ambilKonsumsiKaloriHari();
      setFotoAnak('');
      setUrlFile1('');
      setModalOpen(false);
      setJumlah(0);
      setSelected(null);
      swal('success', 'Data konsumsi anak berhasil disimpan', 'success');
    } else {
      setSavingProses(false);
      swal('Error', 'Data konsumsi anak gagal disimpan', 'error');
    }

    // axios({
    //   method: 'post',
    //   url: POST_MAKAN,
    //   timeout: API_TIMEOUT,
    //   data: dataKu,
    // })
    //   .then((response) => {
    //     if (response.status !== 200) {
    //       // ERROR
    //       return 'error';
    //     } else {
    //       //BERHASIL
    //       setSavingProses(false);
    //       handleAmbilMakan();
    //       ambilKonsumsiKategori();
    //       ambilKonsumsiKaloriHari();
    //       setFotoAnak('');
    //       setUrlFile1('');
    //       setModalOpen(false);
    //       setJumlah(0);
    //       setSelected(null);
    //       swal('success', 'Data konsumsi anak berhasil disimpan', 'success');
    //     }
    //   })
    //   .catch((error) => {
    //     // ERROR
    //     setSavingProses(false);
    //     swal('Error', 'Data konsumsi anak gagal disimpan', 'error');
    //   });
  };

  const handleDeleteDB = (item) => {
    axios({
      method: 'delete',
      url: DELETE_MAKAN + item.id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          handleAmbilMakan();
          ambilKonsumsiKategori();
          ambilKonsumsiKaloriHari();
          swal('Success', 'Data konsumsi anak berhasil dihapus', 'success');
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleAmbilMakan = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    axios({
      method: 'post',
      url: GET_MAKAN,
      timeout: API_TIMEOUT,
      data: {
        uid: idAnak,
        tanggal: date_string,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          setListSelected(response.data.data);
          //console.log(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className='gutter mb-4'>
            <Col xs={12} md={6}>
              <Widget className='widget-p-md'>
                <div className='headline-2'>Daftar menu makanan PMT</div>
                <div className='mt-2 mb-3 body-3 muted'>
                  lakukan pencarian jenis makanan
                </div>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type='text'
                      id='search-input1'
                      onChange={(e) => searchItems(e)}
                    />
                    <InputGroupAddon addonType='append'>
                      <Button color='default'>Search</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                <div style={containerStyle}>
                  <ul className='list-group'>
                    {listTampil ? (
                      listTampil.map((item) => (
                        <li className='list-group-item' key={item.id}>
                          <div className={s.listBadge}>
                            {item.asupan}
                            <Badge
                              color='primary'
                              style={{ cursor: 'pointer' }}
                              onClick={() => selectAction(item)}
                            >
                              <i className='fa fa-plus mr-2' />
                            </Badge>
                          </div>
                        </li>
                      ))
                    ) : (
                      <div />
                    )}
                  </ul>
                </div>
                <div className={s.goals} style={{ margin: 20 }}>
                  <div className='d-flex flex-column mt-3'>
                    <div className={s.activity}>
                      <p className='body-2'>Protein</p>
                      <p className='body-2'>
                        {konsumsiKaloriHari
                          ? konsumsiKaloriHari.total_protein.toFixed(0)
                          : 0}
                        <span className='body-3 muted'>
                          / {kebutuhanKal ? kebutuhanKal.protein : 0} {`(gram)`}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color='secondary-red'
                      className='progress-xs'
                      value={
                        konsumsiKaloriHari && kebutuhanKal
                          ? (
                              (konsumsiKaloriHari.total_protein /
                                kebutuhanKal.protein) *
                              100
                            ).toFixed()
                          : 0
                      }
                    />
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    <div className={s.activity}>
                      <p className='body-2'>Lemak</p>
                      <p className='body-2'>
                        {konsumsiKaloriHari
                          ? konsumsiKaloriHari.total_lemak.toFixed(0)
                          : 0}
                        <span className='body-3 muted'>
                          /{kebutuhanKal ? kebutuhanKal.fat : 0} {`(gram)`}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color='secondary-yellow'
                      className='progress-xs'
                      value={
                        konsumsiKaloriHari && kebutuhanKal
                          ? (
                              (konsumsiKaloriHari.total_lemak /
                                kebutuhanKal.fat) *
                              100
                            ).toFixed()
                          : 0
                      }
                    />
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    <div className={s.activity}>
                      <p className='body-2'>Karbohidrat</p>
                      <p className='body-2'>
                        {konsumsiKaloriHari
                          ? konsumsiKaloriHari.total_karbo.toFixed(0)
                          : 0}
                        <span className='body-3 muted'>
                          /{kebutuhanKal ? kebutuhanKal.carbo : 0} {`(gram)`}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color='secondary-cyan'
                      className='progress-xs'
                      value={
                        konsumsiKaloriHari && kebutuhanKal
                          ? (
                              (konsumsiKaloriHari.total_karbo /
                                kebutuhanKal.carbo) *
                              100
                            ).toFixed()
                          : 0
                      }
                    />
                  </div>
                </div>
              </Widget>
            </Col>
            <Col xs={12} md={6} className='mt-4 mt-md-0'>
              <Widget className='widget-p-md'>
                <FormGroup row>
                  <Label md='3' for='default-select'>
                    Pilih Anak
                  </Label>
                  <Col md='6' className={s.select}>
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color='primary'>
                        {simpleDropdownValue}
                      </DropdownToggle>

                      <DropdownMenu>
                        {listAnaks &&
                          listAnaks.map((item) => (
                            <DropdownItem onClick={changeSelectDropdownSimple}>
                              {item.label}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Label md='3' for='default-select'>
                    <Badge color='danger'>{umur}</Badge> bulan
                  </Label>
                </FormGroup>

                <FormGroup
                  row
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <Label md={5} for='disabled-input' className='text-md-left'>
                    Tanggal Makan
                  </Label>

                  <Col md='7'>
                    <input
                      type='date'
                      value={tanggal}
                      onChange={(e) => setTanggal(e.target.value)}
                      dateFormat='dd/MM/yyyy'
                      placeholder='tanggal lahir'
                    />
                  </Col>
                </FormGroup>
                <div className='mt-2 mb-3 body-3 muted'>
                  Daftar makanan yang dipilih dan selesai dimakan
                </div>
                <ul className='list-group'>
                  {listSelected &&
                    listSelected.map((item, index) => (
                      <li className='list-group-item' key={index}>
                        <Row>
                          <Col>{item.asupan}</Col>
                          <Col>
                            <Badge color='primary'>{item.jumlah}</Badge>
                          </Col>
                          <Col>{item.ukuran}</Col>
                          <Col>
                            {(
                              parseFloat(item.energy) * parseFloat(item.jumlah)
                            ).toFixed(1)}
                            <code>kkal</code>
                          </Col>
                          <Col>
                            <Badge
                              color='secondary-red'
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDeleteDB(item)}
                            >
                              <i className='fa fa-minus mr-2' />
                            </Badge>
                          </Col>
                        </Row>
                      </li>
                    ))}
                </ul>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal isOpen={modalOpen}>
        <ModalBody>
          <FormGroup>
            <Label for='bar'>{selected && selected.asupan}</Label>
            <FormGroup>
              <Label for='bar'>Jumlah / porsi</Label>
            </FormGroup>

            <Row>
              <Col md={8}>
                <InputGroup>
                  <Input type='text' id='bar' value={jumlah} />
                  <InputGroupAddon addonType='append'>
                    <ButtonGroup>
                      <Button
                        color='secondary-red'
                        className='px-3 border-radius-0'
                        onClick={decreaseJumlah}
                        disabled={jumlah <= 0.25} // Disable the button if jumlah is 0.25
                      >
                        <i className={`fa fa-minus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color='secondary-yellow'
                        className='px-3'
                        onClick={increaseJumlah}
                      >
                        <i className={`fa fa-plus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color='success'
                        className='px-3'
                        onClick={resetJumlah}
                      >
                        <i className={`fa fa-refresh ${s.btnIcon}`} />
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={4}>
                <Input
                  type='text'
                  id='disabled-input'
                  disabled='disabled'
                  value={selected && selected.ukuran}
                />
              </Col>
            </Row>
          </FormGroup>
          <Widget className='widget-p-md'>
            <div className='headline-2 mb-3'>Upload foto anak</div>
            <FormGroup row>
              <Label lg='3' className='text-md-right mt-3'>
                File input
              </Label>
              <Col lg='9'>
                <div className='input-group mb-4 px-2 py-2 rounded-pill bg-light-gray'>
                  <input
                    id='upload'
                    type='file'
                    onChange={onChange1}
                    className={`form-control border-0 ${s.upload}`}
                  />
                  <label
                    id='upload-label'
                    htmlFor='upload'
                    className={`font-weight-light text-muted ${s.uploadLabel}`}
                  >
                    {fotoAnak ? fotoAnak.name : 'Pilih file'}
                  </label>
                  <div className='input-group-append'>
                    <label
                      htmlFor='upload'
                      className='btn btn-light m-0 rounded-pill px-4'
                    >
                      <i className='fa fa-cloud-upload mr-2 text-muted'></i>
                    </label>
                  </div>
                </div>
                {urlFile1 && (
                  <div>
                    <div className={`${s.container}`}>
                      <img
                        id='imageResult'
                        src={urlFile1}
                        alt=''
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </FormGroup>
          </Widget>
        </ModalBody>

        <ModalFooter>
          <div className='mx-auto'>
            <Button
              className='btn-rounded btn-outline-secondary mr-3'
              outline
              onClick={() => {
                setModalOpen(false);
                setSelected(null);
                setJumlah(0);
              }}
            >
              Cancel
            </Button>

            {!savingProses ? (
              <Button
                className='btn-rounded'
                color='primary'
                onClick={() => handleSimpan()}
              >
                Simpan
              </Button>
            ) : (
              <Button className='rounded-pill my-3' disabled={true}>
                <Spinner size='sm' color='light' /> Simpan ...
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
