import axios from "axios";
import config from "../config";
import jwt from "jsonwebtoken";

import { push } from "connected-react-router";
import Errors from "../components/FormItems/error/errors";
import { mockUser } from "./mock.js";
import FIREBASE from "../config/FIREBASE/index";
import { API_TIMEOUT, ADMIN_LOGIN } from "../utils";
import swal from "sweetalert";

export const AUTH_FAILURE = "AUTH_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const RESET_REQUEST = "RESET_REQUEST";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const PASSWORD_RESET_EMAIL_REQUEST = "PASSWORD_RESET_EMAIL_REQUEST";
export const PASSWORD_RESET_EMAIL_SUCCESS = "PASSWORD_RESET_EMAIL_SUCCESS";
export const AUTH_INIT_SUCCESS = "AUTH_INIT_SUCCESS";
export const AUTH_INIT_ERROR = "AUTH_INIT_ERROR";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

async function findMe() {
  if (config.isBackend) {
    const response = await axios.get("/auth/me");
    return response.data;
  } else {
    return mockUser;
  }
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function doInit() {
  return async (dispatch) => {
    const currentUser = JSON.parse(localStorage.getItem("user"));

    try {
      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: AUTH_INIT_ERROR,
        payload: error,
      });
    }
  };
}

export const loginSmart = (idUser) => {
  return (dispatch) => {
    FIREBASE.database()
      .ref(`users/${idUser}`)
      .once("value")
      .then((resDB) => {
        // Signed in
        if (resDB.val()) {
          localStorage.setItem("user", JSON.stringify(resDB.val()));
          localStorage.setItem("token", "token");
        } else {
          alert("Failed", "Anda Bukan Admin");
        }
        dispatch(doInit());
        dispatch(push("/template"));
      })
      .catch((error) => {
        alert("Login tidak berhasill");
      });
  };
};

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    axios.defaults.headers.common["Authorization"] = "";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    dispatch(push("/login"));
  };
}

export function receiveToken(token) {
  return (dispatch) => {
    let user;

    if (config.isBackend) {
      user = jwt.decode(token);
    } else {
      user = {
        email: config.auth.email,
        user: {
          id: "default_no_connection_id_444",
        },
      };
    }

    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    dispatch({
      type: LOGIN_SUCCESS,
    });
    dispatch(push("/app"));
  };
}

export const loginUser = (creds) => {
  return (dispatch) => {
    FIREBASE.auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then((res) => {
        FIREBASE.database()
          .ref(`users/${res.user.uid}`)
          .once("value")
          .then((resDB) => {
            // Signed in

            if (resDB.val()) {
              axios({
                method: "post",
                url: ADMIN_LOGIN,
                timeout: API_TIMEOUT,
                data: { uid: resDB.val().uid },
              })
                .then((response) => {
                  if (response.status !== 200) {
                    // ERROR
                  } else {
                    //BERHASIL

                    const result = response.data.data;

                    let statuskalgizi = "user";
                    if (result.length > 0) {
                      statuskalgizi = result[0].status;
                    }

                    const data = resDB.val();
                    data.statuskalgizi = statuskalgizi;
                    creds.setLoginProses(false);
                    swal(
                      "Success",
                      data.nama + " berhasil login sebagai " + statuskalgizi,
                      "success"
                    );

                    //curr.dinas=dataUser.unitKerja

                    //untuk kepentingan development programmmmm
                    //curr.peran = 'admin';
                    //curr.dinas = 'DINAS PERTANIAN';
                    localStorage.setItem("user", JSON.stringify(data));
                    localStorage.setItem("token", "token");
                    dispatch(doInit());
                    dispatch(push("/template"));
                  }
                })
                .catch((error) => {
                  // ERROR
                  //  console.log(creds.email, resDB().uid);

                  swal("Gagal", " Terjadi kesalahan", "error");
                });
            } else {
              alert("Failed", "Anda Bukan Admin");
            }
          })
          .catch((error) => {
            alert("Login tidak berhasill");
          });
      })
      .catch((error) => {
        alert("Login tidak berhasill");
      });
  };
};

export function loginUser2(creds) {
  return (dispatch) => {
    // init UI state settings:
    // localStorage.setItem('dashboardTheme', 'dark')

    //#$$$$$$$$$$$$$### ini pintu masuk untuk login pada aplikasi

    if (!config.isBackend) {
      console.log("tahap 1.5");
      dispatch(receiveToken("token"));
      dispatch(doInit());
      // dispatch(push('/app'));dispatch(push('/template'));
      dispatch(push("/template"));
    } else {
      console.log("tahap 2");
      dispatch({
        type: LOGIN_REQUEST,
      });
      if (creds.social) {
        window.location.href =
          config.baseURLApi +
          "/auth/signin/" +
          creds.social +
          "?template=" +
          config.redirectUrl;
      } else if (creds.email.length > 0 && creds.password.length > 0) {
        console.log("tahap 3");
        axios
          .post("/auth/signin/local", creds)
          .then((res) => {
            const token = res.data;

            dispatch(receiveToken(token));
            dispatch(doInit());
            dispatch(push("/template"));
          })
          .catch((err) => {
            dispatch(authError(err.response.data));
          });
      } else {
        dispatch(authError("Something was wrong. Try again"));
      }
    }
  };
}

export function verifyEmail(token) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push("/login"));
    } else {
      axios
        .put("/auth/verify-email", { token })
        .then((verified) => {
          if (verified) {
            alert("Your email was verified");
          }
        })
        .catch((err) => {
          alert(err.response.data);
        })
        .finally(() => {
          dispatch(push("/login"));
        });
    }
  };
}

export function resetPassword(token, password) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push("/login"));
    } else {
      dispatch({
        type: RESET_REQUEST,
      });
      axios
        .put("/auth/password-reset", { token, password })
        .then((res) => {
          dispatch({
            type: RESET_SUCCESS,
          });
          alert("Password has been updated");
          dispatch(push("/login"));
        })
        .catch((err) => {
          dispatch(authError(err.response.data));
        });
    }
  };
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push("/login"));
    } else {
      dispatch({
        type: PASSWORD_RESET_EMAIL_REQUEST,
      });
      axios
        .post("/auth/send-password-reset-email", { email })
        .then((res) => {
          dispatch({
            type: PASSWORD_RESET_EMAIL_SUCCESS,
          });
          alert("Email with resetting instructions has been sent");
          dispatch(push("/login"));
        })
        .catch((err) => {
          dispatch(authError(err.response.data));
        });
    }
  };
}

export function registerUser(creds) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push("/user/profile"));
    } else {
      dispatch({
        type: REGISTER_REQUEST,
      });

      if (creds.email.length > 0 && creds.password.length > 0) {
        axios
          .post("/auth/signup", creds)
          .then((res) => {
            dispatch({
              type: REGISTER_SUCCESS,
            });
            alert(
              "You've been registered successfully. Please check your email for verification link"
            );
            dispatch(push("/user/profile"));
          })
          .catch((err) => {
            dispatch(authError(err.response.data));
          });
      } else {
        dispatch(authError("Something was wrong. Try again"));
      }
    }
  };
}
