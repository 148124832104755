import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { API_TIMEOUT, GET_STATUS } from "../../utils";
import Akun from "../../assets/profile/akun.png";
import { formatTanggal, hitungUmur, toProperCase } from "../../utils/func";
import s from "./Tables.module.scss";
import { Progress } from "reactstrap";

// Inline CSS styles
const styles = {
  appContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "left",
    padding: "20px",
    flex: 1,
    width: "100%", // Menambahkan ini untuk mengisi 100% lebar
  },
  statusContainer: {
    display: "flex",
    flex: 1,
    marginBottom: "20px",
    width: "100%", // Pastikan ini juga 100%
  },
  status: {
    flex: 2, // Kolom status mengisi 2/3 ruang
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    maxWidth: "600px", // Anda bisa mengatur maksimal lebar jika perlu
  },
  statusDetail: {
    flex: 1, // Status detail hanya menempati 1/3 ruang
    padding: "20px",
    borderLeft: "1px solid #ccc",
    maxWidth: "300px", // Anda bisa mengatur maksimal lebar jika perlu
  },
  statusHeader: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
  statusImage: {
    borderRadius: "50%",
    marginRight: "10px",
    width: "40px",
    height: "40px",
  },
  postImage: {
    width: "100%",
    height: "auto",
  },
  statusCaption: {
    padding: "10px",
    borderTop: "1px solid #ccc",
  },
  statusDate: {
    color: "#666",
    fontSize: "12px",
    textAlign: "right",
    paddingRight: "10px",
  },
};

// Komponen untuk menampilkan masing-masing status dan detailnya
const StatusWithDetail = ({ status }) => {
  const { username, image, caption, date } = status;
  return (
    <div style={styles.statusContainer}>
      <div style={styles.status}>
        <div style={styles.statusHeader}>
          <img
            src={
              status.profile == "-"
                ? Akun
                : status.profile
                ? status.profile
                : Akun
            }
            alt={""}
            style={styles.statusImage}
          />
          <span>
            {status.admin_nama ? toProperCase(status.admin_nama) : "unregister"}
          </span>
        </div>
        <div>
          <img src={status.foto} alt="Post" style={styles.postImage} />
        </div>
        <div style={styles.statusCaption}>
          <p>{caption}</p>
        </div>
        <div style={styles.statusDate}>{date}</div>
      </div>
      <div style={styles.statusDetail}>
        <h3>Detail</h3>
        <p>
          <strong>Tanggal data:</strong> {formatTanggal(status.tanggal)}
        </p>
        <p>
          <strong>Nama Anak:</strong> {status.nama}
        </p>
        <p>
          <strong>Umur:</strong> {hitungUmur(status.dob) + " bulan"}
        </p>
        <p>
          <strong>Desa:</strong> {status.desa}
        </p>
        <p>
          <strong>Data makan:</strong> {status.kategori_list}
        </p>

        <div className="d-flex flex-column mt-3">
          <div className={s.activity}>
            <p className="body-2">Kalori </p>
            <p className="body-2">
              {status.energy_total && status.kalori
                ? ((status.energy_total / status.kalori) * 100).toFixed(0)
                : 0}{" "}
              %
            </p>
          </div>
          <Progress
            color="success"
            className="progress-xs"
            value={
              status.energy_total && status.kalori
                ? ((status.energy_total / status.kalori) * 100).toFixed(0)
                : 0
            }
          />
        </div>
        <div className="d-flex flex-column mt-3">
          <div className={s.activity}>
            <p className="body-2">Protein </p>
            <p className="body-2">
              {status.protein_total && status.protein
                ? ((status.protein_total / status.protein) * 100).toFixed(0)
                : 0}{" "}
              %
            </p>
          </div>
          <Progress
            color="primary"
            className="progress-xs"
            value={
              status.protein_total && status.protein
                ? ((status.protein_total / status.protein) * 100).toFixed(0)
                : 0
            }
          />
        </div>
        <div className="d-flex flex-column mt-3">
          <div className={s.activity}>
            <p className="body-2">Lemak </p>
            <p className="body-2">
              {status.lemak_total && status.fat
                ? ((status.lemak_total / status.fat) * 100).toFixed(0)
                : 0}{" "}
              %
            </p>
          </div>
          <Progress
            color="secondary-red"
            className="progress-xs"
            value={
              status.lemak_total && status.fat
                ? ((status.lemak_total / status.fat) * 100).toFixed(0)
                : 0
            }
          />
        </div>

        <div className="d-flex flex-column mt-3">
          <div className={s.activity}>
            <p className="body-2">Karbohidrat </p>
            <p className="body-2">
              {status.karbohidrat_total && status.carbo
                ? ((status.karbohidrat_total / status.carbo) * 100).toFixed(0)
                : 0}{" "}
              %
            </p>
          </div>
          <Progress
            color="warning"
            className="progress-xs"
            value={
              status.karbohidrat_total && status.carbo
                ? ((status.karbohidrat_total / status.carbo) * 100).toFixed(0)
                : 0
            }
          />
        </div>
      </div>
    </div>
  );
};

// Komponen container untuk list status dengan infinite scrolling
const StatusList = ({ statuses }) => {
  const [visible, setVisible] = useState(3);

  const fetchMoreData = () => {
    setVisible((prevVisible) => prevVisible + 3);
  };

  return (
    <InfiniteScroll
      dataLength={visible}
      next={fetchMoreData}
      hasMore={visible < statuses.length}
      loader={<h4>Loading...</h4>}
    >
      {statuses.slice(0, visible).map((status) => (
        <StatusWithDetail key={status.id} status={status} />
      ))}
    </InfiniteScroll>
  );
};

export default function App() {
  const [statuses, setStatuses] = useState([]);

  const getStatus = () => {
    axios({
      method: "get",
      url: GET_STATUS,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setStatuses(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div style={styles.appContainer}>
      <StatusList statuses={statuses} />
    </div>
  );
}
