import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import Widget from '../../../components/Widget/Widget.js';
import {
  Form,
  Label,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  ButtonGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Progress,
  DropdownMenu,
} from 'reactstrap';
import StarIcon from '../../../assets/badges/StarIcon.js';
import {
  RadialBar,
  RadialBarChart,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import DatePicker from 'react-datepicker';
import s from './Lists.module.scss';

import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import {
  GET_ASUPAN,
  API_TIMEOUT,
  POST_MAKAN,
  DELETE_MAKAN,
  GET_MAKAN,
  GET_USER,
  GET_KEBUTUHAN_KALORI,
  GET_KONSUMSI_KALORI_KATEGORI,
  GET_KONSUMSI_KALORI_HARI,
} from '../../../utils/index.js';

export default function Lists() {
  const [list, setList] = useState([]);
  const [listTampil, setListTampil] = useState([]);
  const [keb, setKeb] = useState(1000);
  const [kebutuhanKal, setKebutuhanKal] = useState(null);
  const [konsumsiKaloriHari, setKonsumsiKaloriHari] = useState(null);
  const [umur, setUmur] = useState();
  const tgl = new Date();
  const year = tgl.getFullYear();
  const month = String(tgl.getMonth() + 1).padStart(2, '0');
  const day = String(tgl.getDate()).padStart(2, '0');
  const date_string = `${year}-${month}-${day}`;

  const [tanggal, setTanggal] = useState(date_string);

  const { makan } = useParams();
  let kategoriKu = makan.split('_');

  if (kategoriKu.length == 1) kategoriKu = kategoriKu[0];
  if (kategoriKu.length == 2) kategoriKu = kategoriKu[0] + ' ' + kategoriKu[1];

  const [kategori, setKategori] = useState(kategoriKu);
  const [kaloriKategori, setKaloriKategori] = useState({
    pagi: undefined,
    siang: undefined,
    malam: undefined,
    snack: undefined,
    pmt: undefined,
    totai: 0,
  });

  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
    setUmur(totalMonthDiff);
    ambilKebutuhanKalori(totalMonthDiff);
  };

  const ambilKonsumsiKaloriHari = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI_HARI,
      timeout: API_TIMEOUT,
      data: {
        tanggal: date_string,
        uid: uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setKonsumsiKaloriHari(response.data.data[0]);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKonsumsiKategori = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI_KATEGORI,
      timeout: API_TIMEOUT,
      data: {
        tanggal: date_string,
        uid: uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          let data = response.data.data;
          //Fungsi untuk mencari data berdasarkan properti
          function findByProperty(array, property, value) {
            return array.find((item) => item[property] === value);
          }

          // Contoh penggunaan
          const pagi = findByProperty(data, 'kategori', 'makan pagi');
          const siang = findByProperty(data, 'kategori', 'makan siang');
          const malam = findByProperty(data, 'kategori', 'makan malam');
          const snack = findByProperty(data, 'kategori', 'snack');
          const pmt = findByProperty(data, 'kategori', 'pmt');

          const d1 = pagi ? (pagi.total_energy ? pagi.total_energy : 0) : 0;
          const d2 = siang ? (siang.total_energy ? siang.total_energy : 0) : 0;
          const d3 = malam ? (malam.total_energy ? malam.total_energy : 0) : 0;
          const d4 = snack ? (snack.total_energy ? snack.total_energy : 0) : 0;

          const d5 = pmt ? (pmt.total_energy ? pmt.total_energy : 0) : 0;
          let total = d1 + d2 + d3 + d4 + d5;
          total =
            total != 0
              ? kebutuhanKal
                ? ((total / kebutuhanKal.kalori) * 100).toFixed(2)
                : 0
              : 0;

          const dataKtgr = {
            pagi: pagi,
            siang: siang,
            malam: malam,
            snack: snack,
            pmt: pmt,
            total: total,
          };
          setKaloriKategori(dataKtgr);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKebutuhanKalori = (age) => {
    axios({
      method: 'post',
      url: GET_KEBUTUHAN_KALORI,
      timeout: API_TIMEOUT,
      data: {
        usia: age,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setKebutuhanKal(response.data.data[0]);
          const kebutuhanKal = response.data.data[0];

          ambilKonsumsiKategori(kebutuhanKal);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const containerStyle = {
    width: '100%',
    height: '300px', // Atur tinggi sesuai kebutuhan Anda
    overflow: 'auto',
    /* Gaya tampilan scroll */
    scrollbarWidth: 'thin',
    scrollbarColor: '#888888 #f0f0f0',
  };

  const radialChartStyle = {
    top: '50%',
    left: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '24px',
  };

  const radialBar = {
    data: [
      {
        name: 'target',
        uv: 100.0,
        pv: 2400,
        fill: '#2F33A7',
      },
      {
        name: 'makan pagi',
        uv:
          kaloriKategori.pagi && kebutuhanKal
            ? (
                (kaloriKategori.pagi.total_energy / kebutuhanKal.kalori) *
                100
              ).toFixed(2)
            : 0,
        pv: 4567,
        fill: '#4d53e0',
      },
      {
        name: 'makan siang',
        uv:
          kaloriKategori.siang && kebutuhanKal
            ? (
                (kaloriKategori.siang.total_energy / kebutuhanKal.kalori) *
                100
              ).toFixed(2)
            : 0,
        pv: 1398,
        fill: '#00A5FF',
      },
      {
        name: 'pmt',
        uv:
          kaloriKategori.pmt && kebutuhanKal
            ? (
                (kaloriKategori.pmt.total_energy / kebutuhanKal.kalori) *
                100
              ).toFixed(2)
            : 0,
        pv: 9800,
        fill: '#43BC13',
      },
      {
        name: 'snack',
        uv:
          kaloriKategori.snack && kebutuhanKal
            ? (
                (kaloriKategori.snack.total_energy / kebutuhanKal.kalori) *
                100
              ).toFixed(2)
            : 0,
        pv: 3908,
        fill: '#FFC405',
      },
      {
        name: 'makan malam',
        uv:
          kaloriKategori.malam && kebutuhanKal
            ? (
                (kaloriKategori.malam.total_energy / kebutuhanKal.kalori) *
                100
              ).toFixed(2)
            : 0,
        pv: 4800,
        fill: '#FFA100',
      },
      {
        name: 'total',
        uv: kaloriKategori.total,
        pv: 4800,
        fill:
          kaloriKategori.total < 50
            ? '#FF5668'
            : kaloriKategori.total > 75
            ? '#43BC13'
            : '#FFC405',
      },
    ],
  };

  const [listSelected, setListSelected] = useState([]);
  const [selected, setSelected] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [jumlah, setJumlah] = useState(0);

  const [uid, setUid] = useState();
  const [nama, setNama] = useState('');

  useEffect(() => {
    const uid = JSON.parse(localStorage.getItem('idUser'));
    const nama = JSON.parse(localStorage.getItem('namaUser'));

    if (uid) {
      setUid(uid);
      ambilUser(uid);
    }
  }, []);

  useEffect(() => {
    ambilAsupanMakanan();
  }, []);

  useEffect(() => {
    handleAmbilMakan();
    if (kebutuhanKal) {
      ambilKonsumsiKategori();
      ambilKonsumsiKaloriHari();
    }
  }, [uid, tanggal, kebutuhanKal]);

  useEffect(() => {
    setListTampil(list);
  }, [list]);

  const ambilUser = (uid) => {
    axios({
      method: 'get',
      url: GET_USER + '/' + uid,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: '',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setNama(response.data.data[0].nama);
          hitungUmur(response.data.data[0].dob);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilAsupanMakanan = () => {
    axios({
      method: 'get',
      url: GET_ASUPAN,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: '',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setList(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const selectAction = (item) => {
    setSelected(item);
    setModalOpen(true);
  };

  const handleSimpan = () => {
    if (jumlah < 1) {
      alert('jumlah harus lebih besar dari 0');
    } else {
      handleSimpanDB();
    }
  };

  const searchItems = (e) => {
    const src = e.target.value;
    if (src == '') {
      setListTampil(list);
    } else {
      const filteredItems = list.filter((item) =>
        item.asupan.includes(src.toLowerCase())
      );
      setListTampil(filteredItems);
    }
  };

  const handleSimpanDB = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    const dataKu = {
      tanggal: date_string,
      kategori: kategori,
      itemMakanan: selected.id,
      uid: uid,
      waktu: '08:09:30',
      jumlah: jumlah,
    };

    axios({
      method: 'post',
      url: POST_MAKAN,
      timeout: API_TIMEOUT,
      data: dataKu,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          handleAmbilMakan();
          ambilKonsumsiKategori();
          ambilKonsumsiKaloriHari();
          setModalOpen(false);
          setJumlah(0);
          setSelected(null);
          alert('Data berhasil disimpan');
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleDeleteDB = (item) => {
    axios({
      method: 'delete',
      url: DELETE_MAKAN + item.id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          handleAmbilMakan();
          ambilKonsumsiKategori();
          ambilKonsumsiKaloriHari();
          alert('Data berhasil dihapus');
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleAmbilMakan = () => {
    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    axios({
      method: 'post',
      url: GET_MAKAN,
      timeout: API_TIMEOUT,
      data: {
        uid: uid,
        tanggal: date_string,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          setListSelected(response.data.data);
          //console.log(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className='gutter mb-4'>
            <Col xs={12} md={6}>
              <Widget className='widget-p-md'>
                <div className='headline-2'>Daftar menu makanan</div>
                <div className='mt-2 mb-3 body-3 muted'>
                  lakukan pencarian jenis makanan
                </div>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type='text'
                      id='search-input1'
                      onChange={(e) => searchItems(e)}
                    />
                    <InputGroupAddon addonType='append'>
                      <Button color='default'>Search</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                <div style={containerStyle}>
                  <ul className='list-group'>
                    {listTampil ? (
                      listTampil.map((item) => (
                        <li className='list-group-item' key={item.id}>
                          <div className={s.listBadge}>
                            {item.asupan}
                            <Badge
                              color='primary'
                              style={{ cursor: 'pointer' }}
                              onClick={() => selectAction(item)}
                            >
                              <i className='fa fa-plus mr-2' />
                            </Badge>
                          </div>
                        </li>
                      ))
                    ) : (
                      <div />
                    )}
                  </ul>
                </div>
                <div className={s.goals} style={{ margin: 20 }}>
                  <div className='d-flex flex-column mt-3'>
                    <div className={s.activity}>
                      <p className='body-2'>Protein</p>
                      <p className='body-2'>
                        {konsumsiKaloriHari
                          ? konsumsiKaloriHari.total_protein.toFixed(0)
                          : 0}
                        <span className='body-3 muted'>
                          / {kebutuhanKal ? kebutuhanKal.protein : 0} {`(gram)`}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color='secondary-red'
                      className='progress-xs'
                      value={
                        konsumsiKaloriHari && kebutuhanKal
                          ? (
                              (konsumsiKaloriHari.total_protein /
                                kebutuhanKal.protein) *
                              100
                            ).toFixed()
                          : 0
                      }
                    />
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    <div className={s.activity}>
                      <p className='body-2'>Lemak</p>
                      <p className='body-2'>
                        {konsumsiKaloriHari
                          ? konsumsiKaloriHari.total_lemak.toFixed(0)
                          : 0}
                        <span className='body-3 muted'>
                          /{kebutuhanKal ? kebutuhanKal.fat : 0} {`(gram)`}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color='secondary-yellow'
                      className='progress-xs'
                      value={
                        konsumsiKaloriHari && kebutuhanKal
                          ? (
                              (konsumsiKaloriHari.total_lemak /
                                kebutuhanKal.fat) *
                              100
                            ).toFixed()
                          : 0
                      }
                    />
                  </div>
                  <div className='d-flex flex-column mt-3'>
                    <div className={s.activity}>
                      <p className='body-2'>Karbohidrat</p>
                      <p className='body-2'>
                        {konsumsiKaloriHari
                          ? konsumsiKaloriHari.total_karbo.toFixed(0)
                          : 0}
                        <span className='body-3 muted'>
                          /{kebutuhanKal ? kebutuhanKal.carbo : 0} {`(gram)`}
                        </span>
                      </p>
                    </div>
                    <Progress
                      color='secondary-cyan'
                      className='progress-xs'
                      value={
                        konsumsiKaloriHari && kebutuhanKal
                          ? (
                              (konsumsiKaloriHari.total_karbo /
                                kebutuhanKal.carbo) *
                              100
                            ).toFixed()
                          : 0
                      }
                    />
                  </div>
                </div>
              </Widget>
            </Col>
            <Col xs={12} md={6} className='mt-4 mt-md-0'>
              <Widget className='widget-p-md'>
                <div className='headline-2'>
                  {nama}{' '}
                  <Badge className='badge-inverse-secondary-cyan' pill>
                    <StarIcon />
                    {kategori}
                  </Badge>
                </div>
                <div className='mt-2 mb-3 body-3 muted'>
                  Daftar makanan yang dipilih dan selesai dimakan
                </div>
                <FormGroup row>
                  <Label md={3} for='disabled-input' className='text-md-right'>
                    Tanggal Makan
                  </Label>
                  <Col md='9'>
                    <input
                      type='date'
                      value={tanggal}
                      onChange={(e) => setTanggal(e.target.value)}
                      dateFormat='dd/MM/yyyy'
                      placeholder='tanggal lahir'
                    />
                  </Col>
                </FormGroup>
                <div className={s.rechartsBlock}>
                  <ResponsiveContainer width='100%' height='100%'>
                    <RadialBarChart
                      cx='50%'
                      cy='50%'
                      innerRadius='10%'
                      outerRadius='80%'
                      barSize={20}
                      data={radialBar.data}
                    >
                      <RadialBar
                        minAngle={15}
                        label={{ position: 'insideStart', fill: '#fff' }}
                        background
                        clockWise
                        dataKey='uv'
                      />
                      <Legend
                        iconSize={10}
                        layout='vertical'
                        verticalAlign='middle'
                        wrapperStyle={radialChartStyle}
                      />
                    </RadialBarChart>
                  </ResponsiveContainer>
                </div>
                <ul className='list-group'>
                  {listSelected &&
                    listSelected.map((item, index) => (
                      <li className='list-group-item' key={index}>
                        <Row>
                          <Col>{item.asupan}</Col>
                          <Col>
                            <Badge color='primary'>{item.jumlah}</Badge>
                          </Col>
                          <Col>{item.ukuran}</Col>
                          <Col>
                            {(
                              parseFloat(item.energy) * parseFloat(item.jumlah)
                            ).toFixed(1)}
                            <code>kkal</code>
                          </Col>
                          <Col>
                            <Badge
                              color='secondary-red'
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDeleteDB(item)}
                            >
                              <i className='fa fa-minus mr-2' />
                            </Badge>
                          </Col>
                        </Row>
                      </li>
                    ))}
                </ul>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal isOpen={modalOpen}>
        <ModalBody>
          <FormGroup>
            <Label for='bar'>{selected && selected.asupan}</Label>
            <Row>
              <Col md={8}>
                <InputGroup>
                  <Input type='text' id='bar' value={jumlah} />
                  <InputGroupAddon addonType='append'>
                    <ButtonGroup>
                      <Button
                        color='secondary-red'
                        className='px-3 border-radius-0'
                        onClick={() => setJumlah(() => jumlah - 1)}
                      >
                        <i className={`fa fa-minus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color='secondary-yellow'
                        className='px-3'
                        onClick={() => setJumlah(() => jumlah + 1)}
                      >
                        <i className={`fa fa-plus ${s.btnIcon}`} />
                      </Button>
                      <Button
                        color='success'
                        className='px-3'
                        onClick={() => setJumlah(0)}
                      >
                        <i className={`fa fa-refresh ${s.btnIcon}`} />
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={4}>
                <Input
                  type='text'
                  id='disabled-input'
                  disabled='disabled'
                  value={selected && selected.ukuran}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <div className='mx-auto'>
            <Button
              className='btn-rounded btn-outline-secondary mr-3'
              outline
              onClick={() => {
                setModalOpen(false);
                setSelected(null);
                setJumlah(0);
              }}
            >
              Cancel
            </Button>
            <Button
              className='btn-rounded'
              color='primary'
              onClick={() => handleSimpan()}
            >
              Simpan
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
