import React, { useState } from 'react';

import Widget from '../../Widget/Widget';
import DatePicker from 'react-datepicker';
import {
  Col,
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';
import {
  POST_USER,
  API_TIMEOUT,
  GET_USER,
  GET_ANAK_DESA,
} from '../../../utils';
import axios from 'axios';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import s from './Elements.module.scss';

import { useDispatch } from 'react-redux';
import { GET_DESA, GET_KECAMATAN } from '../../../utils';
import { push } from 'connected-react-router';
import { update } from 'lodash';
import swal from 'sweetalert';

const UsersForm = () => {
  const [startDate, setStartDate] = useState('');

  const [nama, setNama] = useState('');
  const [nik, setNik] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [catatanKesehatan, setCatatanKesehatan] = useState('');
  const [foto, setFoto] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [namaOrtu, setNamaOrtu] = useState('');
  const [noKK, setNoKK] = useState('');
  const [berat, setBerat] = useState('');
  const [tinggi, setTinggi] = useState('');
  const [idSmartUser, setIdSmartUser] = useState('');
  const [urlFile1, setUrlFile1] = useState();
  const [fotoAnak, setFotoAnak] = useState([]);
  const [rubahGambar, setRubahGambar] = useState('false');

  const { id } = useParams();
  const { idView } = useParams();
  const dispatch = useDispatch();

  const [kecamatan, setKecamatan] = useState('');
  const [desa, setDesa] = useState('');
  const [listDesa, setListDesa] = useState([]);
  const [listDesaPilihan, setListDesaPilihan] = useState([]);
  const [listKecamatan, setListKecamatan] = useState([]);
  const [simpanProses, setSimpanProses] = useState(false);

  const [lila, setLila] = useState('');

  const [listAnakDesa, setListAnakDesa] = useState([]);

  const [idKu, setIdKu] = useState(id);

  useEffect(() => {
    getListDesa();
    getListKecamatan();
    if (!idSmartUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setIdSmartUser(currentUser.uid);
    }
  }, []);

  useEffect(() => {
    if (id) {
      cariData(id);
    }
    if (idView) {
      cariData(idView);
    }
  }, [id, idView]);

  const ubahKecamatan = (kecamatan) => {
    var listDesaPilih = listDesa.filter((item) => {
      return item.kecamatan == kecamatan;
    });

    var listDesaPilihFilter = listDesaPilih.map((item, index) => {
      return { id: index, label: item.desa, value: item.desa };
    });

    setListDesaPilihan(listDesaPilihFilter);

    setListAnakDesa([]);

    setKecamatan(kecamatan);
  };

  const ubahDesa = (desa) => {
    setDesa(desa);
    carianakdesa(desa);
  };

  const ubahNama = (value) => {

    const data = listAnakDesa.filter((item) => {
      return item.id == value;
    });

    if (data.length > 0) {
      setNik(data[0].nik);

      const tgl = new Date(data[0].dob);
      const year = tgl.getFullYear();
      const month = String(tgl.getMonth() + 1).padStart(2, '0');
      const day = String(tgl.getDate()).padStart(2, '0');
      const date_string = `${year}-${month}-${day}`;

      setDob(date_string);
      setGender(data[0].gender);
      setCatatanKesehatan(data[0].catatanKesehatan);

      setKeterangan('');
      setNamaOrtu(data[0].namaOrtu);
      setNoKK('');
      setBerat(data[0].berat);
      setTinggi(data[0].tinggi);
      setLila(data[0].lila);
    }

    setNama(value);
  };

  useEffect(() => {
    if (kecamatan && desa) {
      var listDesaPilih = listDesa.filter((item) => {
        return item.kecamatan == kecamatan;
      });

      var listDesaPilihFilter = listDesaPilih.map((item, index) => {
        return { id: index, label: item.desa, value: item.desa };
      });
      setListDesaPilihan(listDesaPilihFilter);
    }
  }, [desa]);

  const ubahKecamatan2 = (kecamatan, desa) => {
    if (kecamatan && desa) {
      var listDesaPilih = listDesa.filter((item) => {
        return item.kecamatan == kecamatan;
      });

      var listDesaPilihFilter = listDesaPilih.map((item, index) => {
        return { id: index, label: item.desa, value: item.desa };
      });
      setKecamatan(kecamatan);
      setListDesaPilihan(listDesaPilihFilter);
      setDesa(desa);
    }
  };

  const getListDesa = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_DESA);
      data1 = response.data.data;

      if (data1.length > 0) {
        setListDesa(data1);
      } else {
        setListDesa([]);
      }
      // berita.gambarKu = berita.gambar.split(',');
      // berita.isiKu = berita.isi.split('##');
    } catch (error) {
      console.error(error);
    }
  };

  const getListKecamatan = async () => {
    var data1 = [];

    try {
      const response = await axios.get(GET_KECAMATAN);
      data1 = response.data.data;

      if (data1.length > 0) {
        var dataku = data1.map((item, index) => {
          return { id: index, label: item.kecamatan, value: item.kecamatan };
        });
        setListKecamatan(dataku);
      } else {
        setListKecamatan([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const cariData = (id) => {
    const dataKu = { id: id };

    axios({
      method: 'get',
      url: GET_USER + '/' + id,
      timeout: API_TIMEOUT,

      data: dataKu,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const result = response.data.data[0];

          if (response.data.data.length == 0) {
            return;
          }

          const tgl = new Date(result.dob);
          const year = tgl.getFullYear();
          const month = String(tgl.getMonth() + 1).padStart(2, '0');
          const day = String(tgl.getDate()).padStart(2, '0');
          const date_string = `${year}-${month}-${day}`;

          setNama(result.nama);
          setNik(result.nik);

          setDob(date_string);
          setGender(result.gender);
          setCatatanKesehatan(result.catatanKesehatan);
          setFoto(result.foto);
          setKeterangan(result.keterangan);
          setNamaOrtu(result.namaOrtu);
          setNoKK(result.noKK);
          setBerat(result.berat);
          setTinggi(result.tinggi);
          setLila(result.lila);
          setIdSmartUser(result.idSmartUser);

          setUrlFile1(result.profile);
          ubahKecamatan2(result.kecamatan, result.desa);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const carianakdesa = (desa) => {
    const dataKu = { id: id };

    axios({
      method: 'get',
      url: GET_ANAK_DESA + desa,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const result = response.data.data;

          setListAnakDesa(result);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  function onChange1(e) {
    let uploaded = e.target.files[0];
    setUrlFile1(URL.createObjectURL(uploaded));
    setFotoAnak(e.target.files[0]);
    setRubahGambar('true');
  }

  const editData = async () => {
    const tgl = new Date(dob);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    const formData = new FormData();
    formData.append('nama', nama);
    formData.append('nik', nik);
    formData.append('dob', date_string);
    formData.append('gender', gender);
    formData.append('catatanKesehatan', catatanKesehatan);
    formData.append('photo', rubahGambar == 'true' ? fotoAnak : []);
    formData.append('keterangan', keterangan);
    formData.append('namaOrtu', namaOrtu);
    formData.append('desa', desa);
    formData.append('kecamatan', kecamatan);

    formData.append('noKK', noKK);
    formData.append('berat', berat);
    formData.append('tinggi', tinggi);
    formData.append('idSmartUser', idSmartUser);
    formData.append('rubahGambar', rubahGambar);
    formData.append('id', id);
    formData.append('lila', lila);

    const response = await axios.patch(POST_USER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success === 1) {
      setSimpanProses(false);
      swal('Success', 'Data Anak berhasil diedit', 'success');
      dispatch(push('/admin/users'));
    } else {
      setSimpanProses(false);
      swal('Error', 'Data Anak gagal diinput', 'error');
    }
  };

  const tambahData = async () => {
    const tgl = new Date(dob);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    const namaAnak = listAnakDesa.filter((item) => {
      return item.id == nama;
    });

    const formData = new FormData();
    formData.append('nama', namaAnak[0].nama);
    formData.append('nik', nik);
    formData.append('dob', date_string);
    formData.append('gender', gender);
    formData.append('catatanKesehatan', catatanKesehatan);
    formData.append('photo', fotoAnak);
    formData.append('keterangan', keterangan);
    formData.append('namaOrtu', namaOrtu);
    formData.append('desa', desa);
    formData.append('kecamatan', kecamatan);
    formData.append('lila', lila);

    formData.append('noKK', noKK);
    formData.append('berat', berat);
    formData.append('tinggi', tinggi);
    formData.append('idSmartUser', idSmartUser);
    formData.append('idAnak', nama);

    // const dataKu = {
    //   nama: nama,
    //   nik: nik,
    //   dob: dob,
    //   gender: gender,
    //   catatanKesehatan: catatanKesehatan,
    //   foto: foto,
    //   keterangan: keterangan,
    //   namaOrtu: namaOrtu,
    //   noKK: noKK,
    //   berat: berat,
    //   tinggi: tinggi,
    //   idSmartUser: idSmartUser,
    // };

    const response = await axios.post(POST_USER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.success === 1) {
      swal('Success', 'Data Anak berhasil diinput', 'success');
      setNama('');
      setNik('');
      setDob('');
      setGender('');
      setCatatanKesehatan('');
      setFotoAnak([]);
      setKeterangan('');
      setNamaOrtu('');
      setDesa('');
      setKecamatan('');
      setNoKK('');
      setBerat('');
      setTinggi('');
      setUrlFile1('');
      setLila('');
      setSimpanProses(false);
    } else {
      setSimpanProses(false);
      swal('Error', 'Data Anak gagal diinput', 'error');
    }
  };

  const simpanData = async (e) => {
    e.preventDefault();
    if (id) {
      setSimpanProses(true);
      editData();
    } else {
      setSimpanProses(true);
      tambahData();
    }
  };

  const renderForm = () => (
    <Widget className='widget-p-md'>
      <div className='headline-2'>
        {id ? 'Edit' : idView ? 'Lihat' : 'Input'}
      </div>
      <FormGroup>
        <Form onSubmit={(e) => simpanData(e)}>
          <legend className='mt-2'>Data Anak</legend>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Kecamatan
            </Label>
            <Col md={9}>
              <Input
                type='select'
                id='normal-field'
                placeholder='gender'
                value={kecamatan}
                onChange={(e) => ubahKecamatan(e.target.value)}
                required
              >
                <option value={''}> pilih Kecamatan</option>
                {listKecamatan &&
                  listKecamatan.map((item, index) => (
                    <option value={item.label} key={index}>
                      {item.label}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Desa
            </Label>
            <Col md={9}>
              <Input
                type='select'
                id='normal-field'
                placeholder='desa'
                value={desa}
                onChange={(e) => ubahDesa(e.target.value)}
                required
              >
                <option value={''}> pilih Desa</option>
                {listDesaPilihan &&
                  listDesaPilihan.map((item, index) => (
                    <option value={item.label} key={index}>
                      {item.label}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          {!id && !idView ? (
            <FormGroup row>
              <Label md={3} for='normal-field' className='text-md-right'>
                Nama
              </Label>
              <Col md={9}>
                <Input
                  type='select'
                  id='normal-field'
                  value={nama}
                  onChange={(e) => ubahNama(e.target.value)}
                  required
                >
                  <option value={''}> pilih Anak</option>
                  {listAnakDesa &&
                    listAnakDesa.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.nama}
                      </option>
                    ))}
                </Input>
              </Col>
            </FormGroup>
          ) : (
            <FormGroup row>
              <Label md={3} for='normal-field' className='text-md-right'>
                Nama
              </Label>
              <Col md={9}>
                <Input
                  type='text'
                  id='normal-field'
                  placeholder='nama orang tua'
                  value={nama}
                  //  onChange={(e) => setNamaOrtu(e.target.value)}
                  required
                />
              </Col>
            </FormGroup>
          )}
          <FormGroup row>
            <Label
              md={3}
              for='hint-field'
              className='d-flex flex-column text-md-right'
            >
              NIK
              <span className='label muted'>nomor induk kependudukan</span>
            </Label>
            <Col md={9}>
              <Input
                type='text'
                name='password'
                id='hint-field'
                value={nik}
                onChange={(e) => setNik(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='disabled-input' className='text-md-right'>
              Tanggal Lahir
            </Label>
            <Col md='9'>
              <input
                type='date'
                //   onChange={(e) => setDob(e.target.value)}
                dateFormat='dd/MM/yyyy'
                placeholder='tanggal lahir'
                value={dob}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Gender
            </Label>
            <Col md={9}>
              <Input
                type='select'
                id='normal-field'
                placeholder='gender'
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                required
              >
                <option value={''}> pilih gender</option>
                <option value={'Laki - laki'}>Laki - laki</option>
                <option value={'Perempuan'}>Perempuan</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} className='text-md-right' for='default-textarea'>
              Catatan kesehatan
            </Label>
            <Col md={9}>
              <Input
                rows='4'
                type='textarea'
                name='text'
                id='default-textarea'
                placeholder='catatan kesehatan kalau ada'
                value={catatanKesehatan}
                onChange={(e) => setCatatanKesehatan(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Keterangan
            </Label>
            <Col md={9}>
              <Input
                type='text'
                id='normal-field'
                placeholder='keterangan tambahan'
                value={keterangan}
                onChange={(e) => setKeterangan(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Nama Orang Tua
            </Label>
            <Col md={9}>
              <Input
                type='text'
                id='normal-field'
                placeholder='nama orang tua'
                value={namaOrtu}
                //  onChange={(e) => setNamaOrtu(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Nomor KK
            </Label>
            <Col md={9}>
              <Input
                type='text'
                id='normal-field'
                placeholder='nomor Kartu Keluarga'
                value={noKK}
                onChange={(e) => setNoKK(e.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Berat
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='berat badan'
                value={berat}
                //   onChange={(e) => setBerat(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Tinggi
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='tinggi badan'
                value={tinggi}
                //  onChange={(e) => setTinggi(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={3} for='normal-field' className='text-md-right'>
              Lingkar Lengan Atas (lila)
            </Label>
            <Col md={9}>
              <Input
                type='number'
                id='normal-field'
                placeholder='tinggi badan'
                value={lila}
                // onChange={(e) => setTinggi(e.target.value)}
                required
              />
            </Col>
          </FormGroup>

          <Widget className='widget-p-md'>
            <div className='headline-2 mb-3'>Upload foto anak</div>
            <FormGroup row>
              <Label lg='3' className='text-md-right mt-3'>
                File input
              </Label>
              <Col lg='9'>
                <div className='input-group mb-4 px-2 py-2 rounded-pill bg-light-gray'>
                  <input
                    id='upload'
                    type='file'
                    onChange={onChange1}
                    className={`form-control border-0 ${s.upload}`}
                  />
                  <label
                    id='upload-label'
                    htmlFor='upload'
                    className={`font-weight-light text-muted ${s.uploadLabel}`}
                  >
                    {fotoAnak ? fotoAnak.name : 'Pilih file'}
                  </label>
                  <div className='input-group-append'>
                    <label
                      htmlFor='upload'
                      className='btn btn-light m-0 rounded-pill px-4'
                    >
                      <i className='fa fa-cloud-upload mr-2 text-muted'></i>
                    </label>
                  </div>
                </div>
                {urlFile1 && (
                  <div>
                    <div className={`${s.container}`}>
                      <img
                        id='imageResult'
                        src={urlFile1}
                        alt=''
                        className={`${s.imageKu}`}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </FormGroup>
          </Widget>
          {!idView && (
            <FormGroup row>
              <Label md={3} />
              <Col md={9}>
                {!simpanProses ? (
                  <Button color='primary' type='submit' className='mr-3 mt-3'>
                    Simpan
                  </Button>
                ) : (
                  <Button color='primary' className='mr-3 mt-3' disabled={true}>
                    <Spinner size='sm' color='light' /> Simpan ...
                  </Button>
                )}
                <Button
                  color='secondary-red'
                  className='mt-3'
                  type='button'
                  onClick={() => dispatch(push(`/admin/users`))}
                >
                  Cancel
                </Button>
              </Col>
            </FormGroup>
          )}
        </Form>
      </FormGroup>
    </Widget>
  );

  return renderForm();
};

export default UsersForm;
