import React, { useState, useEffect } from "react";

import "eva-icons/style/eva-icons.css";
import DashboardAdmin from "../dashboardAdmin/DashboardAdmin";
import DashboardUser from "./DashboardUser";

export default function Dashboard() {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
    }
  }, []);

  return (
    <div>
      {currentUser ? (
        currentUser.statuskalgizi == "admin" ||
        currentUser.statuskalgizi == "super admin" ? (
          <DashboardAdmin />
        ) : (
          <DashboardUser />
        )
      ) : (
        <></>
      )}
    </div>
  );
}
