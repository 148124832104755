import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withRouter, Link, Redirect } from 'react-router-dom';
import config from '../../../config';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import jwt from 'jsonwebtoken';
import { loginUser, receiveToken, doInit } from '../../../actions/auth';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
  Spinner,
} from 'reactstrap';
import Widget from '../../../components/Widget/Widget';
import Footer from '../../../components/Footer/Footer';

import loginImage from '../../../assets/loginImage.svg';
import SofiaLogo from '../../../components/Icons/SofiaLogo';
import GoogleIcon from '../../../components/Icons/AuthIcons/GoogleIcon.js';
import { loginSmart } from '../../../actions/auth';
const Login = (props) => {
  const myInput = document.getElementById('myInput');
  const inputValue = myInput.value;

  const [idSmart, setIdSmart] = useState(inputValue);

  const [loginProses, setLoginProses] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const loginSmartKu = (e) => {
    setLoginProses(true);

    props.dispatch(loginSmart(inputValue, setLoginProses));
  };

  const doLogin = (e) => {
    setLoginProses(true);
    e.preventDefault();

    props.dispatch(
      loginUser({
        password: state.password,
        email: state.email,
        setLoginProses,
      })
    );
  };

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    if (token) {
      props.dispatch(receiveToken(token));
      props.dispatch(doInit());
    }
  });

  return (
    <div className='auth-page'>
      <Container className='col-12'>
        <Row className='d-flex align-items-center'>
          <Col xs={12} lg={6} className='left-column'>
            <Widget className='widget-auth widget-p-lg'>
              <div className='d-flex align-items-center justify-content-between py-3'>
                <p className='auth-header mb-0'>Login</p>
                <div className='logo-block'>
                  <SofiaLogo />
                  <p className='mb-0'>KAL GIZI</p>
                </div>
              </div>
              <div className='auth-info my-2'>
                {idSmart ? (
                  <p>
                    Aplikasi <b>Kalkulator gizi</b> bagi upaya penangan stunting
                    dan peningkatan gizi anak. Anda sementara mengakses dari{' '}
                    <b>Rote Smart Service</b> Klik tombol di bawah untuk login!
                  </p>
                ) : (
                  <p>
                    Aplikasi <b>Kalkulator gizi</b> bagi upaya penangan stunting
                    dan peningkatan gizi anak{' '}
                    <b>akun rote smart service / password"</b> untuk login!
                  </p>
                )}
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                {idSmart ? (
                  <></>
                ) : (
                  <>
                    <FormGroup className='my-3'>
                      <FormText>Email</FormText>
                      <Input
                        id='email'
                        className='input-transparent pl-3'
                        value={state.email}
                        onChange={(event) => changeCreds(event)}
                        type='email'
                        required
                        name='email'
                        placeholder='Email'
                      />
                    </FormGroup>
                    <FormGroup className='my-3'>
                      <div className='d-flex justify-content-between'>
                        <FormText>Password</FormText>
                        <Link to='/error'>Forgot password?</Link>
                      </div>
                      <Input
                        id='password'
                        className='input-transparent pl-3'
                        value={state.password}
                        onChange={(event) => changeCreds(event)}
                        type='password'
                        required
                        name='password'
                        placeholder='Password'
                      />
                    </FormGroup>
                  </>
                )}
                <div className='bg-widget d-flex justify-content-center'>
                  {idSmart ? (
                    <Button
                      className='rounded-pill my-3'
                      onClick={() => loginSmartKu()}
                      color='primary'
                      disabled={loginProses}
                    >
                      {loginProses ? <Spinner size='sm' color='light' /> : ''}{' '}
                      Login dengan Akun RSS {loginProses ? '...' : ''}
                    </Button>
                  ) : (
                    <Button
                      className='rounded-pill my-3'
                      type='submit'
                      color='secondary-red'
                      disabled={loginProses}
                    >
                      {loginProses ? <Spinner size='sm' color='light' /> : ''}{' '}
                      Login {loginProses ? '...' : ''}
                    </Button>
                  )}
                </div>
                <p className='dividing-line my-3'>&#8195;Or&#8195;</p>
                {!idSmart && (
                  <>
                    <div className='d-flex align-items-center my-3'>
                      <p className='social-label mb-0'>
                        Download Rote Smart Service
                      </p>
                      <div className='socials'>
                        <a href='https://play.google.com/store/apps/details?id=com.rotesmartservice'>
                          <GoogleIcon />
                        </a>
                      </div>
                    </div>
                    <Link to='https://play.google.com/store/apps/details?id=com.rotesmartservice'>
                      Segera buat akun Rote Smart Service
                    </Link>
                  </>
                )}
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className='right-column'>
            <div>
              <img src={loginImage} alt='Error page' />
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
