export const formatDate = (date) => {
  const tgl = new Date(date);
  const year = tgl.getFullYear();
  const month = String(tgl.getMonth() + 1).padStart(2, "0");
  const day = String(tgl.getDate()).padStart(2, "0");
  const date_string = `${day}-${month}-${year}`;
  return date_string;
};

export const hitungUmur = (dob) => {
  const startDate = new Date(dob);
  const today = new Date();

  const yearDiff = today.getFullYear() - startDate.getFullYear();
  const monthDiff = today.getMonth() - startDate.getMonth();
  const totalMonthDiff = yearDiff * 12 + monthDiff;
  return totalMonthDiff;
};

export const formatTanggal = (date) => {
  const tgl = new Date(date);
  const year = tgl.getFullYear();
  const month = String(tgl.getMonth() + 1).padStart(2, "0");
  const day = String(tgl.getDate()).padStart(2, "0");
  const date_string = `${day}-${month}-${year}`;
  return date_string;
};

export function toProperCase(text) {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}
