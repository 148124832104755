import React from 'react';
import ApexCharts from 'react-apexcharts';

export default function ApexActivityChart({ data,kebutuhanKal }) {
  let data1 = [];
  let data2 = [];
  let data3 = [];

  const hari = [
    {
      hari: 'Min',
      index: 0,
    },
    {
      hari: 'Sen',
      index: 1,
    },
    {
      hari: 'Sel',
      index: 2,
    },
    {
      hari: 'Rab',
      index: 3,
    },
    {
      hari: 'Kam',
      index: 4,
    },
    {
      hari: 'Jum',
      index: 5,
    },
    {
      hari: 'Sab',
      index: 6,
    },
  ];

  

  data.map((item) => {
    data1.push(item.tanggal);
    data2.push(item.totalEnergy);
    data3.push(item.standar);
  });

  const series = [
    {
      name: 'konsumsi',
      type: 'column',
      data: data2,
    },
    {
      name: 'standar',
      type: 'line',
      data: data3,
    },
  ];

  const chartSettings = {
    colors: ['#FFCA41', '#43BC13'],
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      width: [0, 1],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      style: {
        fontSize: '10px',
        fontWeight: 500,
      },
      background: {
        borderWidth: 0,
      },
    },
    labels: data1,
    legend: {
      position: 'top',
      floating: true,
    },
    xaxis: {
      type: 'category',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: '#6B859E',
        },
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    grid: {
      show: false,
    },
  };

  return (
    <ApexCharts
      options={chartSettings}
      series={series}
      type='area'
      height={275}
    />
  );
}
