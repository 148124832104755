import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import Check from "../../assets/profile/check.png";
import Uncheck from "../../assets/profile/uncheck.png";

import s from "./Tables.module.scss";
import mock from "./mock.js";
import { GET_ASUPAN, API_TIMEOUT, GET_USER_PMT } from "../../utils/index.js";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

const UserPmt = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable] = useState(mock.secondTable);
  const [transactions, setTransactions] = useState(mock.transactionsWidget);
  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [idHapus, setIdHapus] = useState();

  const [desaUserOk, setDesaUserOk] = useState(0);
  const [simpleDropdownValue2, setSimpleDropdownValue2] = useState("All");

  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState();
  const [statusUser, setStatusUser] = useState("All");
  const listStatus = [
    { id: 0, status: "All" },
    { id: 1, status: "Ok" },
    { id: 2, status: "Belum Ok" },
  ];

  const [datauser, setdatauser] = useState([]);

  const changeSelectDropdownSimple2 = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue2(e.currentTarget.textContent);
    const curAnak = listStatus.filter((item) => {
      return item.status == select;
    });
    // ambilBantuan(curAnak[0].tahun);

    const filter = datauser.filter((item) => {
      if (select == "Ok") {
        return item.count != 0;
      } else if (select == "Belum Ok") {
        return item.count == 0;
      } else {
        return item.count !== "";
      }
    });
    setFirstTable(filter);
    setDesaUserOk(filter.length);
    setStatusUser(curAnak[0].tahun);
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
    }
  }, []);

  function calculateTotalNonZeroCount(data) {
    return data.reduce((total, item) => {
      return item.count !== 0 ? total + item.count : total;
    }, 0);
  }

  useEffect(() => {
    ambilUserPMT();
  }, []);

  const ambilUserPMT = () => {
    axios({
      method: "get",
      url: GET_USER_PMT,
      timeout: API_TIMEOUT,
      data: {
        tanggal: "",
        idUser: "",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setFirstTable(response.data.data);
          setdatauser(response.data.data);
          const userDesa = calculateTotalNonZeroCount(response.data.data);
          setDesaUserOk(userDesa);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const pageSize = 100;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };

  const toggleTask = (id) => {
    setTasks(
      tasks.map((task) => {
        if (task.id === id) {
          task.completed = !task.completed;
        }
        return task;
      })
    );
  };

  const handleDelete = () => {
    axios({
      method: "delete",
      url: GET_ASUPAN + "/" + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          alert("Data komposisi gizi berhasil dihapus");
          const idToDelete = idHapus;
          const filteredArray = firstTable.filter(
            (obj) => obj.id !== idToDelete
          );
          setFirstTable(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;

    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">
                    User Pantau PMT - Kakulator Gizi Berdasarkan Desa{" "}
                    <Badge color="success">{desaUserOk}</Badge>
                  </div>

                  <div className="d-flex">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color="primary">
                        {simpleDropdownValue2}
                      </DropdownToggle>

                      <DropdownMenu>
                        {listStatus &&
                          listStatus.map((item) => (
                            <DropdownItem onClick={changeSelectDropdownSimple2}>
                              {item.status}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                  <a>
                    <ReactHTMLTableToExcel
                      table="datauser"
                      filename={"User Desa"}
                      sheet="user"
                      className="btnKu"
                    />
                  </a>
                </div>
                <div className="widget-table-overflow">
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    id="datauser"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className="checkbox checkbox-primary">
                            <input
                              className="styled"
                              id="checkbox100"
                              type="checkbox"
                            />
                            <label for="checkbox100" />
                          </div>
                        </th>
                        <th className="w-25">DESA</th>
                        <th className="w-25">KECAMATAN</th>

                        <th className="w-25">JUMLAH USER</th>
                        <th className="w-25">KETERANGAN</th>

                        <th className="w-25">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className="checkbox checkbox-primary">
                                <input
                                  id={item.id}
                                  className="styled"
                                  type="checkbox"
                                />
                                <Label for={item.id} />
                              </div>
                            </td>
                            <td>{item.desa}</td>
                            <td>{item.kecamatan}</td>

                            <td>{item.count}</td>

                            <td>
                              {item.count > 0 ? (
                                <img
                                  src={Check}
                                  style={{ width: 30, height: 30 }}
                                />
                              ) : (
                                <img
                                  src={Uncheck}
                                  style={{ width: 30, height: 30 }}
                                />
                              )}
                            </td>
                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <Button
                                  className={s.controlBtn}
                                  color="info"
                                  size="xs"
                                  onClick={() =>
                                    dispatch(
                                      push(`/template/asupan/${item.id}`)
                                    )
                                  }
                                >
                                  View
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className="pagination-borderless"
                    aria-label="Page navigation example"
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href="#top"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size="sm" isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi hapus</ModalHeader>
        <ModalBody>
          apakah anda yakin akan menghapus data komposisi gizi ini?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserPmt;
