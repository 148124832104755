import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";

import Dashboard from "../../pages/dashboard/Dashboard";
import Profile from "../../pages/profile/Profile";
import UserListPage from "../Users/list/UsersListPage";

import UserFormPage from "../Users/form/UserFormPage";

import Notifications from "../../pages/uielements/notifications/Notifications";
import Tables from "../../pages/tables/Tables";

import Cards from "../../pages/uielements/cards/Cards";

import Lists from "../../pages/uielements/lists/Lists";
import Navbars from "../../pages/uielements/navbar/Navbars";
import Navs from "../../pages/uielements/navs/Navs";

import BarCharts from "../../pages/charts/bar/BarCharts";
import LineCharts from "../../pages/charts/line/LineCharts";
import PieCharts from "../../pages/charts/pie/PieCharts";
import OtherCharts from "../../pages/charts/other/OtherCharts";

import Login from "../../pages/auth/login/Login";

import AsupanFormPage from "../../pages/tables/form/AsupanFormPage";

import s from "./Layout.module.scss";
import PMT from "../../pages/uielements/lists/PMT";
import TimbangAnaksListPage from "../Users/list/TimbangAnaksListPage";
import AdminListPage from "../Users/list/AdminListPage";
import DashboardAdmin from "../../pages/dashboardAdmin/DashboardAdmin";
import Analitik from "../../pages/tables/Analitik";
import UserPmt from "../../pages/tables/UserPMT";
import AppStatus from "../../pages/status";

const Layout = (props) => {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
      // logApp(currentUser.uid, 'Dashboard');
    }
  }, []);

  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route
              path="/template"
              exact
              render={() => <Redirect to={"template/dashboard"} />}
            />
            <Route path="/template/dashboard" exact component={Dashboard} />

            <Route
              path="/template/user"
              exact
              render={() => <Redirect to={"/template/user/profile"} />}
            />
            <Route path="/template/user/profile" exact component={Profile} />
            <Route
              path="/admin"
              exact
              render={() => <Redirect to="/admin/users" />}
            />
            <Route path="/admin/users" exact component={UserListPage} />
            <Route path="/admin/users/new" exact component={UserFormPage} />
            <Route
              path="/admin/users/:id/edit"
              exact
              component={UserFormPage}
            />
            <Route path="/admin/users/:idView" exact component={UserFormPage} />

            <Route
              path="/template/asupan/new"
              exact
              component={AsupanFormPage}
            />
            <Route
              path="/template/asupan/:id/edit"
              exact
              component={AsupanFormPage}
            />
            <Route
              path="/template/asupan/:idView"
              exact
              component={AsupanFormPage}
            />

            <Route
              path="/template/edit_profile"
              exact
              component={UserFormPage}
            />

            <Route path="/template/asupan" exact component={Tables} />

            <Route path="/template/makan/asupan" exact component={Cards} />

            <Route
              path="/template/makan/asupan/:makan"
              exact
              component={Lists}
            />
            <Route path="/template/makan/pmt" exact component={PMT} />

            <Route
              path="/template/ui-elements/navbars"
              exact
              component={Navbars}
            />
            <Route path="/template/ui-elements/navs" exact component={Navs} />
            <Route
              path="/template/ui-elements/notifications"
              exact
              component={Notifications}
            />

            <Route
              path="/template/forms"
              exact
              render={() => <Redirect to={"/template/forms/elements"} />}
            />

            <Route
              path="/template/charts"
              exact
              render={() => <Redirect to={"/template/charts/other"} />}
            />

            <Route
              path="/template/timbang/input"
              exact
              component={TimbangAnaksListPage}
            />
            <Route path="/template/pmt/admin" exact component={AdminListPage} />

            <Route path="/template/pmt/user" exact component={UserPmt} />
            <Route path="/template/pmt/status" exact component={AppStatus} />

            <Route path="/template/analitik" exact component={Analitik} />

            <Route path="/template/charts/line" exact component={LineCharts} />
            <Route path="/template/charts/pie" exact component={PieCharts} />
            <Route path="/template/charts/bar" exact component={BarCharts} />
            <Route
              path="/template/charts/other"
              exact
              component={OtherCharts}
            />

            <Route path="/template/extra/login" exact component={Login} />

            <Route path="*" exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
};

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    currentUser: store.auth.currentUser,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
