import React, { useState, useEffect } from 'react';

import {
  Col,
  Row,
  Progress,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';
import { v4 as uuidv4 } from 'uuid';

import 'eva-icons/style/eva-icons.css';

import ApexCharts from 'react-apexcharts';

import swal from 'sweetalert';
//import { logApp } from '../../utils/func.js';

import axios from 'axios';
import {
  API_TIMEOUT,
  GET_REKAP_KEC,
  GET_REKAP_KECAMATAN,
  KATEGORI_UMUR,
} from '../../utils/index.js';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import s from './Dashboard.module.scss';

import Map from '../../components/am4chartMap/am4chartMap.js';

import { logoutUser } from '../../actions/auth.js';
import { useDispatch } from 'react-redux';

export default function DashboardAdmin(props) {
  const [checkboxes, setCheckboxes] = useState([true, false]);

  const [currentUser, setCurrentUser] = useState(null);

  const [simpleDropdownValue, setSimpleDropdownValue] = useState('2023');
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [progresDinas, setProgresDinas] = useState([]);
  const [kec, setKec] = useState('');

  const [modalDesa, setModalDesa] = useState(false);

  const [expire, setExpire] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const dispatch = useDispatch();
  const [secondTable, setSecondTable] = useState([]);
  const pageSize2 = 100;
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize2);

  const doLogout = () => {
    dispatch(logoutUser());
  };
  const refreshToken = async () => {
    // try {
    //   const response = await axios.get(ADMIN_TOKEN, {
    //     credentials: 'include',
    //   });
    //   const decoded = jwt_decode(response.data.accessToken);
    //   setAccessToken(response.data.accessToken);
    //   setExpire(decoded.exp);
    // } catch (error) {
    //   doLogout();
    // }
  };

  const [listPekerjaanPenduduk, setListPekerjaanPenduduk] = useState({
    label: [],
    penduduk: [],
  });

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const [limaTahun, setLimaTahun] = useState({ label: [], data: [] });

  //const axiosJWT = axios.create();

  // axiosJWT.interceptors.request.use(
  //   async (config) => {
  //     const currentDate = new Date();
  //     if (expire * 1000 < currentDate.getTime()) {
  //       const response = await axios.get(ADMIN_TOKEN);
  //       config.headers.Authorization = `Bearer ${response.data.accessToken}`;

  //       const decoded = jwt_decode(response.data.accessToken);
  //     }
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  const highcharts = {
    variableRadiusPie: {
      chart: {
        type: 'variablepie',
      },
      title: {
        text: 'Kategori umur sampel',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
          'Jenis Pekerjaan: <b>{point.y}</b><br/>',
      },
      colors: [
        '#16365f',
        '#41D5E2',
        '#FFC405',
        '#43BC13',
        '#FF5668',
        '#C7D0D9',
        '#00A5FF',
      ],
      series: [
        {
          minPointSize: 10,
          innerSize: '20%',
          zMin: 0,
          name: 'countries',
          data: listPekerjaanPenduduk,
        },
      ],
    },
  };

  const openModal = (data) => {
    getRekapKecamatanDesa(data);
    setKec(data);
    setModalDesa(true);
  };
  const closeModal = () => {
    setKec('');
    setModalDesa(false);
  };

  //auth
  const pekerjaanPenduduk = () => {
    axios({
      method: 'get',
      url: KATEGORI_UMUR,
      timeout: API_TIMEOUT,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.age_group;
          });

          const penduduk = data.map((item) => {
            return item.total;
          });

          let array = [];

          for (let i = 0; i < 5; i++) {
            if (label[i] != 'Petani') {
              const a = {
                name: label[i],
                y: penduduk[i],
                z: Math.floor(Math.random() * 3) + 1,
              };
              array.push(a);
            } else {
              const a = {
                name: label[i],
                y: Math.round(penduduk[i]),
                z: Math.floor(Math.random() * 3) + 1,
              };
              array.push(a);
            }
          }

          setListPekerjaanPenduduk(array);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  //auth

  const [kecamatan, setKecamatan] = useState([
    'Landu',
    'Loaho',
    'Lobal',
    'Ndao',
    'Panbar',
    'Robar',
    'RBD',
    'RBL',
    'Rosel',
    'Roteng',
    'Rotim',
  ]);
  const [jmlOrang, setJmlOrang] = useState([]);
  const [jmlKeluarga, setJmlKeluarga] = useState([]);

  let satMin = [];

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curTahun = listTahun.filter((item) => {
      return item.tahun == select;
    });
  };

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const apexCharts = {
    lineColumn: {
      series: [
        {
          name: 'target sample',
          type: 'column',
          data: jmlOrang,
        },
        {
          name: 'exsisting sample',
          type: 'line',
          data: jmlKeluarga,
        },
      ],
      options: {
        colors: ['#C7D0D9', '#FF5668'],
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: 'straight',
          width: [0, 1],
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
          style: {
            fontSize: '10px',
            fontWeight: 500,
          },
          background: {
            borderWidth: 0,
          },
        },
        labels: kecamatan,
        xaxis: {
          type: 'category',
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: [
          {
            title: {
              text: 'anak',
              style: {
                fontSize: '12px',
                fontWeight: 400,
                color: '#6B859E',
              },
            },
            labels: {
              style: {
                colors: ['#6B859E'],
              },
            },
          },
        ],
        fill: {
          type: 'solid',
          opacity: 1,
        },
      },
    },
    lineArea: {
      series: limaTahun.data,
      options: {
        chart: {
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          type: 'category',
          categories: limaTahun.label,
          labels: {
            style: {
              colors: '#6B859E',
              opacity: 0.7,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#6B859E'],
              opacity: 0.7,
            },
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 1,
            stops: [40, 90, 100],
          },
        },
        colors: ['#4D53E0', '#41D5E2'],
        legend: {
          show: true,
          horizontalAlign: 'center',
        },
      },
    },
  };

  const toggleCheckbox = (id) => {
    setCheckboxes((checkboxes) =>
      checkboxes.map((checkbox, index) => (index === id ? !checkbox : checkbox))
    );
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
      // logApp(currentUser.uid, 'Dashboard');
    }

    getRekapAnakKecamatan();
    pekerjaanPenduduk();

    refreshToken();
    // getDataMiskinKecamatan();
    // getDataMiskinKeluargaKecamatan();
    // getProgresDinas('2023');
    // pekerjaanPenduduk();
    // limaTahunTerakhir();
  }, []);

  const listTahun = [
    { tahun: 2018 },
    { tahun: 2019 },
    { tahun: 2020 },
    { tahun: 2021 },
    { tahun: 2022 },
    { tahun: 2023 },
    { tahun: 2024 },
  ];

  //auth

  const toSmallCaps = (text) => {
    return text.toLowerCase().replace(/(?:^|\s)\S/g, (char) => {
      return char.toUpperCase();
    });
  };

  //auth

  //auth
  const getRekapAnakKecamatan = () => {
    axios({
      method: 'get',
      url: GET_REKAP_KECAMATAN,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          const hasil = response.data.data;
          const jml = hasil.map((item) => {
            return item.jumlah_user;
          });
          const cc = hasil.map((item) => {
            return item.target_anak;
          });

          setProgresDinas(hasil);

          setJmlKeluarga(jml);
          setJmlOrang(cc);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const getRekapKecamatanDesa = (kec) => {
    axios({
      method: 'get',
      url: GET_REKAP_KEC + kec,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          const hasil = response.data.data;
          setSecondTable(hasil);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <div>
      <Row>
        <Col className='pr-grid-col' xs={12} lg={8}>
          <Row className='gutter mb-4'>
            <Col xs={12}>
              <Widget style={{ padding: 20 }}>
                <div className='headline-2 mb-2'>Anak Penerima PMT</div>
                <ApexCharts
                  series={apexCharts.lineColumn.series}
                  options={apexCharts.lineColumn.options}
                />
              </Widget>
            </Col>
          </Row>
          <Row className='gutter'>
            <Col xs={12}>
              <Widget>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={highcharts.variableRadiusPie}
                />
              </Widget>
            </Col>
          </Row>
        </Col>
        <Col className='mt-4 mt-lg-0 pl-grid-col' xs={12} lg={4}>
          <Widget className='widget-p-lg'>
            <div className={s.goalsTitle}>
              <p className='headline-3'>Sample</p>
              <UncontrolledButtonDropdown>
                <DropdownToggle caret color='primary'>
                  {simpleDropdownValue}
                </DropdownToggle>

                <DropdownMenu>
                  {listTahun &&
                    listTahun.map((item) => (
                      <DropdownItem onClick={changeSelectDropdownSimple}>
                        {item.tahun}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            {progresDinas ? (
              progresDinas.map((item) => (
                <div className='d-flex flex-column mt-3'>
                  <div className={s.activity}>
                    <p
                      className='body-2'
                      style={{ color: '#00008B', cursor: 'pointer' }}
                    >
                      <Badge onClick={() => openModal(item.kecamatan)}>
                        {item.kecamatan}{' '}
                      </Badge>
                    </p>
                    <p className='body-2' style={{ cursor: 'pointer' }}>
                      {item.jumlah_user}
                      <span className='body-3 muted'>/{item.target_anak}</span>
                    </p>
                  </div>
                  <Progress
                    color={
                      (item.jumlah_user / item.target_anak) * 100 < 50
                        ? 'danger'
                        : (item.jumlah_user / item.target_anak) * 100 >= 50 &&
                          (item.jumlah_user / item.target_anak) * 100 <= 90
                        ? 'warning'
                        : 'success'
                    }
                    className='progress-xs'
                    value={(item.jumlah_user / item.target_anak) * 100}
                  />
                </div>
              ))
            ) : (
              <div />
            )}
          </Widget>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col className='pr-grid-col' xs={12} lg={12}>
          <Widget style={{ padding: 20 }}>
            <Map />
          </Widget>
        </Col>
      </Row>
      <Modal size='md' isOpen={modalDesa} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>
          <div className={s.tableTitle}>
            <div className='headline-2'>
              {'Kecamatan'}
              <code> {kec}</code>
            </div>
          </div>
        </ModalHeader>

        <Widget className='widget-p-md'>
          <div className='widget-table-overflow'>
            <Table
              id='dataBantuan'
              className={`table-striped table-borderless table-hover ${s.statesTable}`}
              responsive
            >
              <thead>
                <tr>
                  <th className='w-15'>Desa</th>
                  <th className='w-25'>Jumlah Sample</th>
                  <th className='w-25'>Progress</th>
                  <th className='w-25'>Persentase</th>
                </tr>
              </thead>
              <tbody>
                {secondTable
                  .slice(
                    secondTableCurrentPage * pageSize2,
                    (secondTableCurrentPage + 1) * pageSize2
                  )
                  .map((item) => (
                    <tr key={uuidv4()}>
                      <td>{item.desa}</td>
                      <td>{item.jumlah_user}</td>

                      <td>
                        <code>
                          {item.jumlah_user == 0 ? ' kosong ' : ' proses '}
                        </code>
                      </td>
                      <td>
                        <div className={`d-flex justify-content-between`}>
                          <div className='d-flex flex-column mt-3'>
                            <div className={s.activity}>
                              <p className='body-2'>
                                {Math.round((item.jumlah_user / 5) * 100)}%
                                Sample{' '}
                              </p>
                              <p className='body-2'>
                                {item.jumlah_user}
                                <span className='body-3 muted'>/{5}</span>
                              </p>
                            </div>
                            <Progress
                              color={
                                (item.jumlah_user / 5) * 100 > 50
                                  ? 'success'
                                  : 'danger'
                              }
                              className='progress-xs'
                              value={(item.jumlah_user / 5) * 100}
                            />
                          </div>
                        </div>
                      </td>
                      <td>{item.kecamatan}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Pagination
              className='pagination-borderless'
              aria-label='Page navigation example'
            >
              <PaginationItem disabled={secondTableCurrentPage <= 0}>
                <PaginationLink
                  onClick={(e) =>
                    setSecondTablePage(e, secondTableCurrentPage - 1)
                  }
                  previous
                  href='#top'
                />
              </PaginationItem>
              {[...Array(secondTablePagesCount)].map((page, i) => (
                <PaginationItem active={i === secondTableCurrentPage} key={i}>
                  <PaginationLink
                    onClick={(e) => setSecondTablePage(e, i)}
                    href='#top'
                  >
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                disabled={secondTableCurrentPage >= secondTablePagesCount - 1}
              >
                <PaginationLink
                  onClick={(e) =>
                    setFirstTablePage(e, secondTableCurrentPage + 1)
                  }
                  next
                  href='#top'
                />
              </PaginationItem>
            </Pagination>
          </div>
        </Widget>
      </Modal>
    </div>
  );
}
