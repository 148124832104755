import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';

import cloudIcon from '../../assets/tables/cloudIcon.svg';
import funnelIcon from '../../assets/tables/funnelIcon.svg';
import optionsIcon from '../../assets/tables/optionsIcon.svg';
import printerIcon from '../../assets/tables/printerIcon.svg';
import searchIcon from '../../assets/tables/searchIcon.svg';
import moreIcon from '../../assets/tables/moreIcon.svg';

import s from './Tables.module.scss';
import mock from './mock.js';
import { GET_ASUPAN, API_TIMEOUT } from '../../utils/index.js';
import axios from 'axios';

import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const Tables = function () {
  const [firstTable, setFirstTable] = useState([]);
  const [secondTable] = useState(mock.secondTable);
  const [transactions, setTransactions] = useState(mock.transactionsWidget);
  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [idHapus, setIdHapus] = useState();

  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
    }
  }, []);

  useEffect(() => {
    ambilAsupanMakanan();
  }, []);

  const ambilAsupanMakanan = () => {
    axios({
      method: 'get',
      url: GET_ASUPAN,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: '',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const pageSize = 20;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  };

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  };

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  };

  const toggleTask = (id) => {
    setTasks(
      tasks.map((task) => {
        if (task.id === id) {
          task.completed = !task.completed;
        }
        return task;
      })
    );
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: GET_ASUPAN + '/' + idHapus,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          alert('Data komposisi gizi berhasil dihapus');
          const idToDelete = idHapus;
          const filteredArray = firstTable.filter(
            (obj) => obj.id !== idToDelete
          );
          setFirstTable(filteredArray);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };
  const openModal = (cell) => {
    const id = cell;

    setIdHapus(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };

  return (
    <div>
      <Row>
        <Col>
          <Row className='mb-4'>
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className='headline-2'>
                    Komposisi gizi berdasarkan jenis makanan{' '}
                  </div>
                  <div className='d-flex'>
                    {currentUser ? (
                      currentUser.statuskalgizi == 'admin' ? (
                        <Button
                          color='primary'
                          onClick={() => dispatch(push('/template/asupan/new'))}
                        >
                          tambah
                          <img src={searchIcon} alt='Search' />
                        </Button>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    <a href='/#'>
                      <img
                        className='d-none d-sm-block'
                        src={cloudIcon}
                        alt='Cloud'
                      />
                    </a>
                    <a href='/#'>
                      <img src={printerIcon} alt='Printer' />
                    </a>
                    <a href='/#'>
                      <img
                        className='d-none d-sm-block'
                        src={optionsIcon}
                        alt='Options'
                      />
                    </a>
                    <a href='/#'>
                      <img src={funnelIcon} alt='Funnel' />
                    </a>
                  </div>
                </div>
                <div className='widget-table-overflow'>
                  <Table
                    className={`table-striped table-borderless table-hover ${s.statesTable}`}
                    responsive
                  >
                    <thead>
                      <tr>
                        <th className={s.checkboxCol}>
                          <div className='checkbox checkbox-primary'>
                            <input
                              className='styled'
                              id='checkbox100'
                              type='checkbox'
                            />
                            <label for='checkbox100' />
                          </div>
                        </th>
                        <th className='w-25'>FOTO</th>
                        <th className='w-25'>ASUPAN</th>
                        <th className='w-25'>UKURAN</th>
                        <th className='w-25'>GRAM</th>
                        <th className='w-25'>ENERGY</th>
                        <th className='w-25'>PROTEIN</th>
                        <th className='w-25'>LEMAK</th>
                        <th className='w-25'>KARBOHIDRAT</th>
                        <th className='w-25'>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstTable
                        .slice(
                          firstTableCurrentPage * pageSize,
                          (firstTableCurrentPage + 1) * pageSize
                        )
                        .map((item) => (
                          <tr key={uuidv4()}>
                            <td>
                              <div className='checkbox checkbox-primary'>
                                <input
                                  id={item.id}
                                  className='styled'
                                  type='checkbox'
                                />
                                <Label for={item.id} />
                              </div>
                            </td>
                            <td className='d-flex align-items-center'>
                              <img
                                className={s.image}
                                src={
                                  item.img
                                    ? item.img
                                    : 'https://mamaboi.rotendaokab.go.id/mamaboi/diet.png'
                                }
                                alt='User'
                              />
                              <span className='ml-3'>{item.name}</span>
                            </td>

                            <td>{item.asupan}</td>
                            <td>{item.ukuran}</td>
                            <td>{item.gram}</td>
                            <td>{item.energy}</td>
                            <td>{item.protein}</td>
                            <td>{item.lemak}</td>
                            <td>{item.karbohidrat}</td>
                            <td>
                              <div className={`d-flex justify-content-between`}>
                                <Button
                                  className={s.controlBtn}
                                  color='info'
                                  size='xs'
                                  onClick={() =>
                                    dispatch(
                                      push(`/template/asupan/${item.id}`)
                                    )
                                  }
                                >
                                  View
                                </Button>
                                {currentUser ? (
                                currentUser.statuskalgizi == 'super admin'  ? (
                                    <>
                                      <Button
                                        className={`${s.controlBtn} mx-2`}
                                        color='success'
                                        size='xs'
                                        onClick={() =>
                                          dispatch(
                                            push(
                                              `/template/asupan/${item.id}/edit`
                                            )
                                          )
                                        }
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        className={s.controlBtn}
                                        color='danger'
                                        size='xs'
                                        onClick={() => {
                                          openModal(item.id);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  <Pagination
                    className='pagination-borderless'
                    aria-label='Page navigation example'
                  >
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage - 1)
                        }
                        previous
                        href='#top'
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) => (
                      <PaginationItem
                        active={i === firstTableCurrentPage}
                        key={i}
                      >
                        <PaginationLink
                          onClick={(e) => setFirstTablePage(e, i)}
                          href='#top'
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem
                      disabled={
                        firstTableCurrentPage >= firstTablePagesCount - 1
                      }
                    >
                      <PaginationLink
                        onClick={(e) =>
                          setFirstTablePage(e, firstTableCurrentPage + 1)
                        }
                        next
                        href='#top'
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal size='sm' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Konfirmasi hapus</ModalHeader>
        <ModalBody>
          apakah anda yakin akan menghapus data komposisi gizi ini?
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Tables;
