import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup";
import { changeActiveSidebarItem } from "../../actions/navigation.js";
import SofiaLogo from "../Icons/SofiaLogo.js";
import "eva-icons/style/eva-icons.css";

const Sidebar = (props) => {
  const { activeItem = "", ...restProps } = props;

  const [burgerBtnToggled, setBurgerBtnToggled] = useState(false);

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      setCurrentUser(currentUser);
    }
  }, []);

  useEffect(() => {
    if (props.sidebarOpened) {
      setBurgerBtnToggled(true);
    } else {
      setTimeout(() => {
        setBurgerBtnToggled(false);
      }, 0);
    }
  }, [props.sidebarOpened]);

  return (
    <nav className={cn(s.root, { [s.sidebarOpen]: burgerBtnToggled })}>
      <header className={s.logo}>
        <SofiaLogo />
        <span className={s.title}>KAL GIZI - PANTAU PMT</span>
      </header>
      <ul className={s.nav}>
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Dashboard"
          isHeader
          iconName={<i className="eva eva-home-outline" />}
          link="/template/dashboard"
          index="dashboard"
          badge={
            currentUser
              ? currentUser.statuskalgizi == "admin" ||
                currentUser.statuskalgizi == "super admin"
                ? "A"
                : "U"
              : ""
          }
        />

        <h5 className={[s.navTitle, s.groupTitle].join(" ")}>DATA</h5>

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Profile"
          isHeader
          iconName={<i className="eva eva-person-outline" />}
          link="/template/user"
          index="user"
          exact={false}
          childrenLinks={[
            {
              header: "Profile",
              link: "/template/user/profile",
            },
          ]}
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Daftar Anak"
          isHeader
          iconName={<i className="eva eva-people-outline" />}
          link="/admin"
          index="admin"
          exact={false}
          childrenLinks={[
            {
              header: "Management Data Anak",
              link: "/admin/users",
            },
            {
              header: "Tambah Data Anak",
              link: "/admin/users/new",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Asupan"
          isHeader
          iconName={<i className="eva eva-grid-outline" />}
          link="/template/asupan"
          index="tables"
        />
        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Makan"
          isHeader
          iconName={<i className="eva eva-options-outline" />}
          link="/template/makan"
          index="makan"
          childrenLinks={[
            {
              header: "Makan",
              link: "/template/makan/asupan",
            },
            {
              header: "PMT",
              link: "/template/makan/pmt",
            },
          ]}
        />
        {currentUser ? (
          currentUser.statuskalgizi == "admin" ||
          currentUser.statuskalgizi == "super admin" ? (
            <LinksGroup
              onActiveSidebarItemChange={(activeItem) =>
                props.dispatch(changeActiveSidebarItem(activeItem))
              }
              activeItem={props.activeItem}
              header="Pmt"
              isHeader
              iconName={<i className="eva eva-pantone-outline" />}
              link="/template/pmt"
              index="pmt"
              childrenLinks={[
                {
                  header: "PMT Admin",
                  link: "/template/pmt/admin",
                },
                {
                  header: "PMT User",
                  link: "/template/pmt/user",
                },
                {
                  header: "PMT Status",
                  link: "/template/pmt/status",
                },
              ]}
            />
          ) : (
            <div></div>
          )
        ) : (
          <div></div>
        )}

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Timbang"
          isHeader
          iconName={<i className="eva eva-battery-outline" />}
          link="/template/timbang"
          index="timbang"
          childrenLinks={[
            {
              header: "Input hasil timbang",
              link: "/template/timbang/input",
            },
          ]}
        />

        <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header="Analitik"
          isHeader
          iconName={<i className="eva eva-pie-chart-outline" />}
          link="/template/analitik"
          index="analitik"
          childrenLinks={[
            {
              header: "Analisis data anak",
              link: "/template/analitik",
            },
          ]}
        />

        {/* <LinksGroup
          onActiveSidebarItemChange={(activeItem) =>
            props.dispatch(changeActiveSidebarItem(activeItem))
          }
          activeItem={props.activeItem}
          header='Analitiks'
          isHeader
          iconName={<i className='eva eva-pie-chart-outline' />}
          link='/template/charts'
          index='charts'
          childrenLinks={[
            {
              header: 'Bar charts',
              link: '/template/charts/bar',
            },
            {
              header: 'Pie charts',
              link: '/template/charts/pie',
            },
            {
              header: 'Line charts',
              link: '/template/charts/line',
            },
            {
              header: 'Other charts',
              link: '/template/charts/other',
            },
          ]}
        /> */}
      </ul>
    </nav>
  );
};

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
