import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as dataFormat from './UsersDataFormatters';
import actions from '../../../actions/usersListActions';
import axios from 'axios';
import { GET_USER, API_TIMEOUT, POST_TIMBANG } from '../../../utils';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  FormGroup,
  Col,
  Input,
} from 'reactstrap';
import CustomModal from '../../CustomModal/CustomModal';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Widget from '../../Widget/Widget';

import s from '../Users.module.scss';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';

const { SearchBar } = Search;

const TimbangAnaksListTable = (props) => {
  const { rows, dispatch, loading, idToDelete, modalOpen } = props;
  const [tableWidth, setTableWidth] = useState(window.innerWidth);
  const [rowsTable, setRowsTable] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [rowsKu, setRowsKu] = useState([]);
  const [idTimbang, setIdTimbang] = useState();

  const [modalDelete, setModalDelete] = useState(false);
  const [berat, setBerat] = useState('');
  const [tinggi, setTinggi] = useState('');
  const [catatan, setCatatan] = useState('');
  const [tanggal, setTanggal] = useState('');
  const [nama, setNama] = useState('');
  const [lila, setLila] = useState('');
  const dispatchKu = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
    }
  }, []);
  useEffect(() => {
    const uid = currentUser ? currentUser.uid : null;
    if (uid) ambilUser();
  }, [currentUser]);

  const ambilUser = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: currentUser.uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          if (response.data.data.length > 0) {
            const data = response.data.data;
            data.map((item, index) => {
              const umur = hitungUmur(item.dob);
              data[index].dob = umur + ' .b';
            });

            setRowsKu(data);
          } else {
            ambilUserDummy();
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const ambilUserDummy = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: 'xxxxxx',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          if (response.data.data.length > 0) {
            const data = response.data.data;
            data.map((item, index) => {
              const umur = hitungUmur(item.dob);
              data[index].dob = umur + ' .b';
            });

            setRowsKu(data);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
      });
  };

  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
    return totalMonthDiff;
  };

  const handleSimpan = () => {
    if (!tinggi || !berat || !tanggal ||lila) {
      swal(
        'Error',
        'Data tinggi, berat, tanggal dan lila harus diisi sebelum disimpan',
        'error'
      );
      return;
    }

    const data = {};

    const tgl = new Date(tanggal);
    const year = tgl.getFullYear();
    const month = String(tgl.getMonth() + 1).padStart(2, '0');
    const day = String(tgl.getDate()).padStart(2, '0');
    const date_string = `${year}-${month}-${day}`;

    data.uid = idTimbang;
    data.tinggi = tinggi;
    data.berat = berat;
    data.catatan = catatan;
    data.tanggal = date_string;
    data.lila = lila;

    axios({
      method: 'post',
      url: POST_TIMBANG,
      timeout: API_TIMEOUT,
      data: data,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL
          swal(
            'Success',
            'Data hasil timbang anak berhasil disimpan',
            'success'
          );

          ambilUser();
          onCloseModal();
        }
      })
      .catch((error) => {
        // ERROR
        alert(error);
        onCloseModal();
      });
  };

  const onCloseModal = () => {
    setTinggi('');
    setBerat('');
    setCatatan('');
    setIdTimbang('');
    setTanggal('');
    setLila('');
    setModalDelete(false);
  };
  const openModal = (cell) => {
    const id = cell;

    const nama = rowsKu.filter((item) => {
      return item.id == id;
    });

    setNama(nama[0].nama);
    setIdTimbang(id);
    setModalDelete(true);
  };
  const closeModal = () => {
    setModalDelete(false);
  };
  const actionFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        <Button
          className={s.controlBtn}
          color='info'
          size='xs'
          onClick={() => {
            openModal(cell);
          }}
        >
          Timbang
        </Button>
      </div>
    );
  };

  const beratFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        {parseFloat(cell).toFixed(2)} kg
      </div>
    );
  };

  const lilaFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        {parseFloat(cell).toFixed(2)} cm
      </div>
    );
  };

  const tinggiFormatter = (cell) => {
    return (
      <div className={`d-flex justify-content-between`}>
        {parseFloat(cell).toFixed(2)} cm
      </div>
    );
  };

  const updateWindowDimensions = () => {
    setTableWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(actions.doFetch({}));
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  const columns = [
    {
      dataField: 'avatars',
      sort: true,
      formatter: dataFormat.imageFormatter,
      text: 'Avatar',
    },
    {
      dataField: 'nama',
      sort: true,
      text: 'nama',
    },
    {
      dataField: 'dob',
      sort: true,
      text: 'Umur (bulan)',
    },
    {
      dataField: 'gender',
      sort: true,
      text: 'gender',
    },
    {
      dataField: 'berat',
      sort: true,
      text: 'berat',
      formatter: beratFormatter,
    },
    {
      dataField: 'tinggi',
      sort: true,
      text: 'tinggi',
      formatter: tinggiFormatter,
    },
    {
      dataField: 'lila',
      sort: true,
      text: 'lila',
      formatter: lilaFormatter,
    },
    {
      dataField: 'catatanKesehatan',
      sort: true,

      text: 'catatan',
    },
    {
      dataField: 'id',
      formatter: actionFormatter,
      text: 'Actions',
    },
  ];

  return (
    <div>
      <Widget className='widget-p-md'>
        <ToolkitProvider columns={columns} data={rowsKu} keyField='id' search>
          {(props) => (
            <React.Fragment>
              <SearchBar
                className='react-bootstrap-table-search-input my-3'
                {...props.searchProps}
              />

              <BootstrapTable
                bordered={false}
                classes={`table-striped table-hover mt-4 ${
                  tableWidth < 1150 ? 'table-responsive' : ''
                }`}
                pagination={paginationFactory()}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </Widget>
      <Modal size='sm' isOpen={modalDelete} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>{nama}</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label md={4} for='disabled-input' className='text-md-left'>
              Berat (kg)
            </Label>
            <Col md='8'>
              <Input
                type='number'
                id='normal-field'
                placeholder=''
                value={berat}
                onChange={(e) => setBerat(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} for='disabled-input' className='text-md-left'>
              Tinggi (cm)
            </Label>
            <Col md='8'>
              <Input
                type='number'
                id='normal-field'
                placeholder=''
                value={tinggi}
                onChange={(e) => setTinggi(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} for='disabled-input' className='text-md-left'>
              Lila (cm)
            </Label>
            <Col md='8'>
              <Input
                type='number'
                id='normal-field'
                placeholder=''
                value={lila}
                onChange={(e) => setLila(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} for='disabled-input' className='text-md-left'>
              Catatan
            </Label>
            <Col md='8'>
              <Input
                rows='4'
                type='textarea'
                name='text'
                id='default-textarea'
                placeholder=''
                value={catatan}
                onChange={(e) => setCatatan(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md={4} for='disabled-input' className='text-md-left'>
              Tanggal timbang
            </Label>
            <Col md='8'>
              <input
                type='date'
                id='normal-field'
                placeholder='tinggi badan'
                value={tanggal}
                onChange={(e) => setTanggal(e.target.value)}
                required
              />
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={() => onCloseModal()}>
            Cancel
          </Button>
          <Button color='primary' onClick={() => handleSimpan()}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    loading: store.users.list.loading,
    rows: store.users.list.rows,
    modalOpen: store.users.list.modalOpen,
    idToDelete: store.users.list.idToDelete,
  };
}

export default connect(mapStateToProps)(TimbangAnaksListTable);
