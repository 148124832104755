import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
  Label,
  Badge,
  Progress
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import TaskContainer from "./components/TaskContainer/TaskContainer.js";

import cloudIcon from "../../assets/tables/cloudIcon.svg";
import funnelIcon from "../../assets/tables/funnelIcon.svg";
import optionsIcon from "../../assets/tables/optionsIcon.svg";
import printerIcon from "../../assets/tables/printerIcon.svg";
import searchIcon from "../../assets/tables/searchIcon.svg";
import moreIcon from "../../assets/tables/moreIcon.svg";
import axios from "axios";

import { API_TIMEOUT, GET_DATA_MAKAN, GET_KEBUTUHAN_KALORI, GET_KONSUMSI_KALORI } from "../../utils/index.js";
import s from "./Tables.module.scss";
import mock from "./mock.js";
import { GET_DATA_ANALITIK } from "../../utils/index.js";

import profile from '../../assets/profile/bayi.png'
import makan_img from '../../assets/profile/makan.png'
import { formatDate } from "../../utils/func.js";

const Analitik = function () {

  const [firstTable,setFirstTable] = useState([]);
  const [secondTable,setSecondTable] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [tasks, setTasks] = useState(mock.tasksWidget);
  const [firstTableCurrentPage, setFirstTableCurrentPage] = useState(0);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const pageSize = 5;
  const firstTablePagesCount = Math.ceil(firstTable.length / pageSize);
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);
  const [simpleDropdownValue, setSimpleDropdownValue] = useState('');
  const [currentAnak,setCurrentAnak=useState]=useState('')
  const [konsumsiKal,setKonsumsiKal]=useState()
  const [kebutuhanKal,setKebutuhanKal]=useState()
  const [dob,setDob]=useState('')


  const [listMakan,setListMakan]=useState([])

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curAnak = firstTable.filter((item) => {
      return item.nama == select;
    });
    setKebutuhanKal()
    setKonsumsiKal()
    setCurrentAnak(curAnak[0].id);
    setDob(curAnak[0].dob);
  };


 

  const ambilKebutuhanKalori = (dob) => {

    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
   
    axios({
      method: 'post',
      url: GET_KEBUTUHAN_KALORI,
      timeout: API_TIMEOUT,
      data: {
        usia: totalMonthDiff,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setKebutuhanKal(response.data.data[0]);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };


  useEffect(()=>{
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
    }

  },[])

  useEffect(()=>{

    if (currentUser){
      ambilAnalitikData()
    }

  },[currentUser])

  const ambilAnalitikData=()=>{
    axios({
      method: 'post',
      url: GET_DATA_ANALITIK,
      timeout: API_TIMEOUT,
      data: {
        idUser: currentUser.uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setFirstTable(response.data.data);
          setSecondTable(response.data.data)
          setSimpleDropdownValue((response.data.data[0].nama))

          setCurrentAnak((response.data.data[0].id))
          setDob((response.data.data[0].dob))

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  }

  useEffect(()=>{

    if (currentAnak && dob){
      
      ambilDataMakan(currentAnak)
      ambilKebutuhanKalori(dob)
      ambilKonsumsiKalori(currentAnak)
    }

  },[currentAnak,dob])


  const ambilKonsumsiKalori = (idAnak) => {
    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI,
      timeout: API_TIMEOUT,
      data: {
        interval: "WEEK",
        uid: idAnak,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          if (response.data.data.length > 0) {
            setKonsumsiKal(response.data.data[0]);

           
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };



  const ambilDataMakan=(idAnak)=>{
    axios({
      method: 'post',
      url: GET_DATA_MAKAN,
      timeout: API_TIMEOUT,
      data: {
        idAnak: idAnak,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setTransactions(response.data.data);
   
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  }



  const setFirstTablePage = (e, index) => {
    e.preventDefault();
    setFirstTableCurrentPage(index);
  }

  const setSecondTablePage = (e, index) => {
    e.preventDefault();
    setSecondTableCurrentPage(index);
  }

  const tableMenuOpen = () => {
    setTableMenuOpen(!tableDropdownOpen);
  }

  const toggleTask = (id) => {
    setTasks(
      tasks.map( task => {
      if (task.id === id) {
        task.completed = !task.completed;
      }
      return task;
      })
    )
  }

  return (
    <div>
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Analisa berat anak </div>
                  <div className="d-flex">
                    <a href="/#"><img src={searchIcon} alt="Search"/></a>
                    <a href="/#"><img className="d-none d-sm-block" src={cloudIcon} alt="Cloud" /></a>
                    <a href="/#"><img src={printerIcon} alt="Printer" /></a>
                    <a href="/#"><img className="d-none d-sm-block" src={optionsIcon} alt="Options" /></a>
                    <a href="/#"><img src={funnelIcon} alt="Funnel" /></a>
                  </div>
                </div>
                <div className="widget-table-overflow">
                  <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                    <thead>
                    <tr>
                      <th className={s.checkboxCol}>
                        <div className="checkbox checkbox-primary">
                          <input
                            className="styled"
                            id="checkbox100"
                            type="checkbox"
                          />
                          <label for="checkbox100"/>
                        </div>
                      </th>
                      <th className="w-25">NAMA</th>
                      <th className="w-25">USIA (bulan)</th>
                      <th className="w-25">BERAT AWAL</th>
                      <th className="w-25">BERAT TIMBANG</th>
                      <th className="w-25">STANDAR MIN</th>
                      <th className="w-40">ANALISA</th>
                      <th className="w-25">KETERANGAN</th>
                    </tr>
                    </thead>
                    <tbody>
                    {firstTable
                      .slice(
                        firstTableCurrentPage * pageSize,
                        (firstTableCurrentPage + 1) * pageSize
                      )
                      .map(item => (
                        <tr key={uuidv4()}>
                          <td>
                            <div className="checkbox checkbox-primary">
                              <input
                                id={item.id}
                                className="styled"
                                type="checkbox"
                              />
                              <Label for={item.id} />
                            </div>
                          </td>
                          <td className="d-flex align-items-center"><img className={s.image} src={profile} alt="User"/><span className="ml-3">{item.nama}</span></td>
                          <td>{item.usia_dalam_bulan}</td>
                          <td>{parseFloat(item.berat_awal).toFixed(0)}</td>
                          <td>{item.tanggalTimbang?parseFloat(item.berat).toFixed(0):'NULL'}</td>
                          <td>{item.berat_badan_min}</td>
                          <td><div className={`d-flex justify-content-between`}>
                                <div className='d-flex flex-column mt-3'>
                                  <div className={s.activity}>
                                    <p className='body-2'>
                                      {Math.round(
                                        (((item.tanggalTimbang?parseFloat(item.berat):parseFloat(item.berat_awal)) /
                                          parseFloat(item.berat_badan_min))*100) 
                                      )}
                                      % mencapai{' '}
                                    </p>
                                    <p className='body-2'>
                                      {item.tanggalTimbang? parseFloat(item.berat).toFixed(0):parseFloat(item.berat_awal).toFixed(0)}
                                      <span className='body-3 muted'>
                                        /{parseFloat( item.berat_badan_min).toFixed(1)}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      (item.tanggalTimbang?item.berat:item.berat_awal / item.berat_badan_min) *
                                        100 >
                                      95
                                        ? 'success'
                                        : 'danger'
                                    }
                                    className='progress-xs'
                                    value={
                                      (item.tanggalTimbang?item.berat:item.berat_awal / item.berat_badan_min) *
                                      100
                                    }
                                  />
                                </div>
                              </div></td>

                             <td>{(item.tanggalTimbang?item.berat:item.berat_awal / item.berat_badan_min) *100 >
                                      95
                                        ? 'ok'
                                        : 'not ok'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination className="pagination-borderless" aria-label="Page navigation example">
                    <PaginationItem disabled={firstTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={e => setFirstTablePage(e, firstTableCurrentPage - 1)}
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(firstTablePagesCount)].map((page, i) =>
                      <PaginationItem active={i === firstTableCurrentPage} key={i}>
                        <PaginationLink onClick={e => setFirstTablePage(e, i)} href="#top">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem disabled={firstTableCurrentPage >= firstTablePagesCount - 1}>
                      <PaginationLink
                        onClick={e => setFirstTablePage(e, firstTableCurrentPage + 1)}
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Analisa tinggi anak</div>
                  <Dropdown
                    className="d-none d-sm-block"
                    nav
                    isOpen={tableDropdownOpen}
                    toggle={() => tableMenuOpen()}
                  >
                    <DropdownToggle nav>
                      <img className="d-none d-sm-block" src={moreIcon} alt="More..."/>
                    </DropdownToggle>
                    <DropdownMenu >
                      <DropdownItem>Copy</DropdownItem>
                      <DropdownItem>Edit</DropdownItem>
                      <DropdownItem>Delete</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="widget-table-overflow">
                  <Table className="table-striped table-borderless table-hover" responsive>
                    <thead>
                    <tr>
                      <th>
                        <div className="checkbox checkbox-primary">
                          <input
                            id="checkbox200"
                            className="styled"
                            type="checkbox"
                          />
                          <label for="checkbox200"/>
                        </div>
                      </th>
                      <th className={s.nameCol}>NAMA</th>
                     
                      <th className="w-25">USIA (bulan)</th>
                      <th className="w-25">TINGGI AWAL</th>
                      <th className="w-25">TINGGI TIMBANG</th>
                      <th className="w-25">STANDAR MIN</th>
                      <th className="w-40">ANALISA</th>
                      <th className="w-25">KETERANGAN</th>
                    </tr>
                    </thead>
                    <tbody>
                    {secondTable
                      .slice(
                        secondTableCurrentPage * pageSize,
                        (secondTableCurrentPage + 1) * pageSize
                      )
                      .map(item => (
                        <tr key={uuidv4()}>
                          <td>
                            <div className="checkbox checkbox-primary">
                              <input
                                id={item.id}
                                className="styled"
                                type="checkbox"
                              />
                              <label for={item.id} />
                            </div>
                          </td>
                          <td>{item.nama}</td>
                          <td>{item.usia_dalam_bulan}</td>
                          <td>{item.tinggi_awal}</td>
                          <td>{item.tanggalTimbang?item.tinggi:'NULL'}</td>
                          <td>{item.tinggi_badan_min}</td>
                          <td><div className={`d-flex justify-content-between`}>
                                <div className='d-flex flex-column mt-3'>
                                  <div className={s.activity}>
                                    <p className='body-2'>
                                      {Math.round(
                                        (((item.tanggalTimbang?parseFloat(item.tinggi):parseFloat(item.tinggi_awal)) /
                                          parseFloat(item.tinggi_badan_min))*100) 
                                      )}
                                      % mencapai{' '}
                                    </p>
                                    <p className='body-2'>
                                      {item.tanggalTimbang? parseFloat(item.tinggi).toFixed(0):parseFloat(item.tinggi_awal).toFixed(0)}
                                      <span className='body-3 muted'>
                                        /{parseFloat( item.tinggi_badan_min).toFixed(1)}
                                      </span>
                                    </p>
                                  </div>
                                  <Progress
                                    color={
                                      Math.round(
                                        (((item.tanggalTimbang?parseFloat(item.tinggi):parseFloat(item.tinggi_awal)) /
                                          parseFloat(item.tinggi_badan_min))*100) 
                                      ) >
                                      95
                                        ? 'success'
                                        : 'danger'
                                    }
                                    className='progress-xs'
                                    value={
                                      Math.round(
                                        (((item.tanggalTimbang?parseFloat(item.tinggi):parseFloat(item.tinggi_awal)) /
                                          parseFloat(item.tinggi_badan_min))*100) 
                                      )
                                    }
                                  />
                                </div>
                              </div></td>
                          <td><Badge color={Math.round(
                                        (((item.tanggalTimbang?parseFloat(item.tinggi):parseFloat(item.tinggi_awal)) /
                                          parseFloat(item.tinggi_badan_min))*100) 
                                      )>
                                      95
                                        ? 'success'
                                        : 'danger'}>{Math.round(
                                          (((item.tanggalTimbang?parseFloat(item.tinggi):parseFloat(item.tinggi_awal)) /
                                            parseFloat(item.tinggi_badan_min))*100) 
                                        )>
                                        95
                                          ? 'ok'
                                          : 'not ok'}</Badge></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Pagination className="pagination-with-border">
                    <PaginationItem disabled={secondTableCurrentPage <= 0}>
                      <PaginationLink
                        onClick={e => setSecondTablePage(e, secondTableCurrentPage - 1)}
                        previous
                        href="#top"
                      />
                    </PaginationItem>
                    {[...Array(secondTablePagesCount)].map((page, i) =>
                      <PaginationItem active={i === secondTableCurrentPage} key={i}>
                        <PaginationLink onClick={e => setSecondTablePage(e, i)} href="#top">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem disabled={secondTableCurrentPage >= secondTablePagesCount - 1}>
                      <PaginationLink
                        onClick={e => setSecondTablePage(e, secondTableCurrentPage + 1)}
                        next
                        href="#top"
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              </Widget>
            </Col>
          </Row>
          <Row>
            <Col xs={12} xl={8} className="pr-grid-col">
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Riwayat makan</div>
                  <div>
                  <UncontrolledButtonDropdown>
                      <DropdownToggle caret color='primary'>
                        {simpleDropdownValue}
                      </DropdownToggle>

                      <DropdownMenu>
                        {firstTable &&
                          firstTable.map((item) => (
                            <DropdownItem onClick={changeSelectDropdownSimple}>
                              {item.nama}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
                <div className={s.widgetContentBlock}>
                  {transactions.map(item => (
                    <div key={uuidv4()} className={s.content}>
                      <div><img src={makan_img} style={{width:30, height:30}} alt="Item" /></div>
                      <div className="body-3 muted d-none d-md-block">{( formatDate( item.tanggal))}</div>
                      <div className="body-2">{item.kategori}</div>
                      <div className="body-3 muted d-none d-lg-block">{item.asupan}</div>
                      <div className="body-3 muted d-none d-lg-block"><Badge>Detail</Badge></div>
                   
                    </div>
                  ))}
                </div>
              </Widget>
            </Col>
            <Col xs={12} xl={4} className="pl-grid-col mt-4 mt-xl-0">
              <Widget>
                <div className={s.tableTitle}>
                  <div className="headline-2">Analisa konsumsi vs kebutuhan</div>
                </div>
                <div className={s.widgetContentBlock}>
                <div className={s.goals}>
              <div className={s.goalsTitle}>
                <p className='headline-3'>Konsumsi ({konsumsiKal?konsumsiKal.jumlah_hari:''} hari)</p>
                
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Kalori </p>
                  <p className='body-2'>
                    { konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_energy /
                            (kebutuhanKal.kalori * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0} %
                  </p>
                </div>
                <Progress
                  color='primary'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_energy /
                            (kebutuhanKal.kalori * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Protein</p>
                  <p className='body-2'>
                    {  konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_protein /
                            (kebutuhanKal.protein * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0} %
                  </p>
                </div>
                <Progress
                  color='secondary-red'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_protein /
                            (kebutuhanKal.protein * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Lemak</p>
                  <p className='body-2'>
                    { konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_lemak / (kebutuhanKal.fat * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0} %
                  </p>
                </div>
                <Progress
                  color='secondary-yellow'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_lemak / (kebutuhanKal.fat * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Karbohidrat</p>
                  <p className='body-2'>
                   {konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_karbo / (kebutuhanKal.carbo * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0} %
                  </p>
                </div>
                <Progress
                  color='secondary-cyan'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_karbo / (kebutuhanKal.carbo * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
            </div>
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Analitik;
