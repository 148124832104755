export const API_KALORI = "https://kalkulatorgizi.rotendaokab.go.id/api/";
//'http://localhost:4000/api/admin/';

//'https://smartservice.rotendaokab.go.id/api/admin/';
//export const API_SMART = 'http://192.168.43.39:4000/api/';

export const GET_UKURAN = API_KALORI + "ukuran";
export const GET_ASUPAN = API_KALORI + "asupan";
export const POST_ASUPAN = API_KALORI + "asupan/input";
export const GET_USER = API_KALORI + "user";
export const POST_USER = API_KALORI + "user/input";

export const POST_MAKAN = API_KALORI + "makan/input";
export const POST_MAKAN_PMT = API_KALORI + "makan/input/pmt";

export const POST_TIMBANG = API_KALORI + "timbang/input";
export const DELETE_MAKAN = API_KALORI + "makan/";
export const GET_MAKAN = API_KALORI + "makan/ambil";
export const GET_REKAP_DESA = API_KALORI + "rekap/desa";
export const GET_REKAP_KECAMATAN = API_KALORI + "rekap/kecamatan";
export const ADMIN_LOGIN = API_KALORI + "admin/login";
export const KATEGORI_UMUR = API_KALORI + "rekap/kategori/umur";

export const GET_STATUS = API_KALORI + "pmt/status";

export const GET_REKAP_KEC = API_KALORI + "rekap/desa/";

export const GET_ANAK_DESA = API_KALORI + "anak/";

export const GET_USER_PMT = API_KALORI + "user/pmt";
export const GET_KONSUMSI_KALORI = API_KALORI + "makan/konsumsi";
export const GET_KEBUTUHAN_KALORI = API_KALORI + "kebutuhankalori";
export const GET_KONSUMSI_KALORI_KATEGORI =
  API_KALORI + "makan/konsumsi/kategori";
export const GET_KONSUMSI_KALORI_HARI = API_KALORI + "konsumsi/kalori/hari";
export const LIST_ADMIN_KG = API_KALORI + "kalkulatorgizi/admin/";

export const GET_KECAMATAN = API_KALORI + "kecamatan";
export const GET_DESA = API_KALORI + "desa";

export const GET_DATA_ANALITIK = API_KALORI + "analitik/anak";

export const GET_DATA_MAKAN = API_KALORI + "analitik/makan/anak";

export const API_TIMEOUT = 120000;
