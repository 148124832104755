import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Col,
  Row,
  Progress,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledButtonDropdown,
  FormGroup,
  Label,
  Badge,
} from 'reactstrap';
import Widget from '../../components/Widget/Widget.js';
import ApexActivityChart from './components/ActivityChart.js';
import 'eva-icons/style/eva-icons.css';

import ApexCharts from 'react-apexcharts';
import meal1 from '../../assets/dashboard/meal-1.svg';
import meal2 from '../../assets/dashboard/meal-2.svg';
import meal3 from '../../assets/dashboard/meal-3.svg';
import upgradeImage from '../../assets/dashboard/upgradeImage.svg';
import heartRed from '../../assets/dashboard/heartRed.svg';
import heartTeal from '../../assets/dashboard/heartTeal.svg';
import heartViolet from '../../assets/dashboard/heartViolet.svg';
import heartYellow from '../../assets/dashboard/heartYellow.svg';
import gymIcon from '../../assets/dashboard/gymIcon.svg';
import therapyIcon from '../../assets/dashboard/therapyIcon.svg';

import axios from 'axios';
import {
  GET_USER,
  API_TIMEOUT,
  GET_KEBUTUHAN_KALORI,
  GET_KONSUMSI_KALORI,
} from '../../utils/index.js';

import s from './Dashboard.module.scss';
import { formatDate } from '../../utils/func.js';

export default function DashboardUser() {
  const [checkboxes, setCheckboxes] = useState([true, false]);
  const [interval, setInterval] = useState('WEEK');
  const [currentUser, setCurrentUser] = useState(null);
  const [currentAnak, setCurrentAnak] = useState(null);
  const [kebutuhanKal, setKebutuhanKal] = useState(null);
  const [konsumsiKal, setKonsumsiKal] = useState(null);
  const [satuMinggu, setSatuMinggu] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [protein, setProtein] = useState([]);
  const [karbo, setKarbo] = useState([]);
  const [lemak, setLemak] = useState([]);
  const [axis, setAxis] = useState([]);
  const [listKonsumsiTerbanyak, setListKonsumsiTerbanyak] = useState([]);
  const [simpleDropdownValue, setSimpleDropdownValue] = useState('');
  const [anak, setAnak] = useState([]);
  const [umur, setUmur] = useState();

  let satMin = [];

  const changeSelectDropdownSimple = (e) => {
    const select = e.currentTarget.textContent;
    setSimpleDropdownValue(e.currentTarget.textContent);
    const curAnak = anak.filter((item) => {
      return item.nama == select;
    });
    setCurrentAnak(curAnak[0]);
  };

  useEffect(() => {
    if (!graphData) return;

    let data1 = [];
    let data2 = [];
    let data3 = [];
    let data4 = [];

    const hari = [
      {
        hari: 'Min',
        index: 0,
      },
      {
        hari: 'Sen',
        index: 1,
      },
      {
        hari: 'Sel',
        index: 2,
      },
      {
        hari: 'Rab',
        index: 3,
      },
      {
        hari: 'Kam',
        index: 4,
      },
      {
        hari: 'Jum',
        index: 5,
      },
      {
        hari: 'Sab',
        index: 6,
      },
    ];

    graphData.map((item) => {
      data1.push(formatDate(item.tanggal));
      data2.push(item.totalProtein);
      data3.push(item.totalKarbo);
      data4.push(item.totalLemak);
    });

    setProtein(data2);
    setKarbo(data3);
    setLemak(data4);
    setAxis(data1);
  }, [graphData]);

  const apexCharts = {
    basicWithLabels: {
      series: [
        {
          name: 'protein',
          data: protein,
        },
        {
          name: 'karbohidrat',
          data: karbo,
        },
        {
          name: 'lemak',
          data: lemak,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        colors: ['#FF4B23', '#00A5FF', '#00A5FG'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'straight',
        },
        title: {
          text: 'Konsumsi Gizi Anak',
          align: 'center',
        },
        grid: {
          row: {
            colors: ['#F7F8FB', 'transparent'],
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: axis
            ? axis
            : ['sen', 'sel', 'rab', 'kam', 'jum', 'sab', 'min'],
          labels: {
            style: {
              colors: '#6B859E',
            },
          },
        },
        yaxis: {
          title: {
            text: 'asupan',
            style: {
              fontSize: '12px',
              fontWeight: 400,
              color: '#6B859E',
            },
          },
          labels: {
            style: {
              colors: ['#6B859E'],
            },
          },
          min: 5,
          max: 200,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    },
  };

  const toggleCheckbox = (id) => {
    setCheckboxes((checkboxes) =>
      checkboxes.map((checkbox, index) => (index === id ? !checkbox : checkbox))
    );
  };

  useEffect(() => {
    if (!currentUser) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      setCurrentUser(currentUser);
    }
  }, []);
  useEffect(() => {
    const uid = currentUser ? currentUser.uid : null;
    if (uid) ambilUser();
  }, [currentUser]);

  useEffect(() => {
    if (currentAnak) {
      hitungUmur(currentAnak.dob);

      ambilKonsumsiKaloriHari();
      ambilKonsumsiTerbanyak();
    }
  }, [currentAnak]);

  useEffect(() => {
    if (kebutuhanKal) {
      ambilKonsumsiKaloriHari();
      ambilKonsumsiTerbanyak();
    }
  }, [kebutuhanKal]);

  const hitungUmur = (dob) => {
    const startDate = new Date(dob);
    const today = new Date();

    const yearDiff = today.getFullYear() - startDate.getFullYear();
    const monthDiff = today.getMonth() - startDate.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;
    setUmur(totalMonthDiff);

    ambilKebutuhanKalori(totalMonthDiff);
  };

  useEffect(() => {
    if (satuMinggu.length > 1 && currentAnak) {
      setDataGrafik();
    }
  }, [satuMinggu, currentAnak]);

  useEffect(() => {
    if (currentAnak && interval) ambilKonsumsiKalori();
  }, [currentAnak, interval]);

  const ambilUser = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: currentUser.uid,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          if (response.data.data.length > 0) {
            setAnak(response.data.data);
            setCurrentAnak(response.data.data[0]);
            setSimpleDropdownValue(response.data.data[0].nama);
          } else {
            ambilUserDummy();
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilUserDummy = () => {
    axios({
      method: 'post',
      url: GET_USER,
      timeout: API_TIMEOUT,
      data: {
        tanggal: '',
        idUser: 'xxxxxx',
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          if (response.data.data.length > 0) {
            setAnak(response.data.data);
            setCurrentAnak(response.data.data[0]);
            setSimpleDropdownValue(response.data.data[0].nama);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKebutuhanKalori = (age) => {
    axios({
      method: 'post',
      url: GET_KEBUTUHAN_KALORI,
      timeout: API_TIMEOUT,
      data: {
        usia: age,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setKebutuhanKal(response.data.data[0]);
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKonsumsiKalori = () => {
    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI,
      timeout: API_TIMEOUT,
      data: {
        interval: interval,
        uid: currentAnak.id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          if (response.data.data.length > 0) {
            setKonsumsiKal(response.data.data[0]);
          }
          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKonsumsiKaloriHari = (jumlah) => {
    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI + '/hari',
      timeout: API_TIMEOUT,
      data: {
        jumlah: jumlah,
        uid: currentAnak.id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          //  setSatuMinggu(response.data.data);
          const dataKu = response.data.data;

          
          var today = new Date();

          const dataFormat= dataKu.map

          // Array untuk menyimpan tanggal
          var dateArray = [];

          // Menambahkan tanggal ke array untuk satu minggu ke depan
          for (var i = 7; i > 0; i--) {
            var currentDate = new Date(today);
            currentDate.setDate(currentDate.getDate() - i);
            dateArray.push(currentDate);
          }
          var mergedData = dataKu.map(function (item) {
            

            return {
              id: item.id,
              tanggal:formatDate(item.tanggal),
              totalEnergy: item.total_energy.toFixed(0)
                ,
              totalProtein: item.total_protein.toFixed(0)
                ,
              totalLemak: item.total_lemak.toFixed(0)
                ,
              totalKarbo: item.total_karbo.toFixed(0)
                ,
              standar: kebutuhanKal ? kebutuhanKal.kalori : 0,
            };
          });

  

          setGraphData(mergedData);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilKonsumsiTerbanyak = () => {
    axios({
      method: 'post',
      url: GET_KONSUMSI_KALORI + '/terbanyak',
      timeout: API_TIMEOUT,
      data: {
        uid: currentAnak.id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return 'error';
        } else {
          //BERHASIL

          setListKonsumsiTerbanyak(response.data.data);

          //setFirstTable(response.data.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const setDataGrafik = () => {
    var today = new Date();

    // Array untuk menyimpan tanggal
    var dateArray = [];

    // Menambahkan tanggal ke array untuk satu minggu ke depan
    for (var i = 0; i < 7; i++) {
      var currentDate = new Date(today);
      currentDate.setDate(currentDate.getDate() - i);
      dateArray.push(currentDate);
    }

    var mergedData = dateArray.map(function (item) {
      var matchingData = satuMinggu.find(function (obj) {
        return new Date(obj.tanggal).toDateString() === item.toDateString();
      });

      return {
        id: item,
        totalEnergy: matchingData ? matchingData.total_energy.toFixed(0) : 0,
        totalProtein: matchingData ? matchingData.total_protein.toFixed(0) : 0,
        totalLemak: matchingData ? matchingData.total_lemak.toFixed(0) : 0,
        totalKarbo: matchingData ? matchingData.total_karbo.toFixed(0) : 0,
        standar: kebutuhanKal ? kebutuhanKal.kalori : 0,
      };
    });

    setGraphData(mergedData);
  };

  const meals = [meal1, meal2, meal3];

  return (
    <div>
      <Row>
        <Col className='pr-grid-col' xs={12} lg={8}>
          <Row className='gutter mb-4'>
            <Col className='mb-4 mb-md-0' xs={12} md={6}>
              <Widget className=''>
                <div className='d-flex justify-content-between widget-p-md'>
                  <div className='headline-3 d-flex align-items-center'>
                    konsumsi anak
                  </div>

                </div>
                <ApexActivityChart
                  className='pb-4'
                  data={graphData}
                  kebKalori={kebutuhanKal}
                />
              </Widget>
            </Col>
            <Col xs={12} md={6}>
              <Widget className='widget-p-md'>
                <div className='d-flex justify-content-between'>
                  <div className='headline-3 d-flex align-items-center'>
                    Asupan Makanan
                  </div>
                  
                </div>
                {listKonsumsiTerbanyak.map((meal) => (
                  <div key={uuidv4()} className={`mt-4 ${s.widgetBlock}`}>
                    <div className={s.widgetBody}>
                      <div className='d-flex'>
                        <img
                          className='img-fluid mr-2'
                          style={{ width: 40, height: 40, borderRadius: 20 }}
                          src={
                            'https://mamaboi.rotendaokab.go.id/mamaboi/diet.png'
                          }
                          alt='...'
                        />
                        <div className='d-flex flex-column'>
                          <p className='body-2'>{meal.asupan}</p>
                          <p className='body-3 muted'>{meal.gram} g</p>
                        </div>
                      </div>
                      <div className='body-3 muted'>{meal.energy} kkal</div>
                    </div>
                  </div>
                ))}
              </Widget>
            </Col>
          </Row>
          <Row className='gutter mb-4'>
            <Col xs={12}>
              <Widget style={{ padding: 20 }}>
                <ApexCharts
                  series={apexCharts.basicWithLabels.series}
                  options={apexCharts.basicWithLabels.options}
                />
              </Widget>
            </Col>
          </Row>
          <Row className='gutter'>
            <Row>
              <Col>
                <p className='headline-3'>
                  {'Kebutuhan harian untuk '}{' '}
                  <Badge color='success'>
                    {currentAnak && currentAnak.nama}
                  </Badge>
                  {' usia '}
                  <Badge color='primary'>{umur && umur} .b</Badge>
                </p>
              </Col>
            </Row>
            <Row>
              <Col className='mb-4 mb-xl-0' xs={6} sm={6} xl={3}>
                <Widget className='widget-p-sm'>
                  <div className={s.smallWidget}>
                    <div className='d-flex mb-4'>
                      <img
                        className='py-1 mr-2 img-fluid'
                        src={heartRed}
                        alt='...'
                      />
                      <div className='d-flex flex-column'>
                        <p className='headline-3'>Kalori</p>
                        <p className='body-2'>
                          {kebutuhanKal && kebutuhanKal.kalori}
                          <span className='body-3 muted'> kkal</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <Progress
                        color='secondary-red'
                        className={`progress-xs ${s.mutedPink}`}
                        value='75'
                      />
                    </div>
                  </div>
                </Widget>
              </Col>
              <Col className='mb-4 mb-xl-0' xs={6} sm={6} xl={3}>
                <Widget className='widget-p-sm'>
                  <div className={s.smallWidget}>
                    <div className='d-flex mb-4'>
                      <img
                        className='py-1 mr-2 img-fluid'
                        src={heartYellow}
                        alt='...'
                      />
                      <div className='d-flex flex-column'>
                        <p className='headline-3'>Protein</p>
                        <p className='body-2'>
                          {kebutuhanKal && kebutuhanKal.protein}
                          <span className='body-3 muted'> gram</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <Progress
                        color='secondary-yellow'
                        className={`progress-xs ${s.mutedYellow}`}
                        value='75'
                      />
                    </div>
                  </div>
                </Widget>
              </Col>
              <Col xs={6} sm={6} xl={3}>
                <Widget className='widget-p-sm'>
                  <div className={s.smallWidget}>
                    <div className='d-flex mb-4'>
                      <img
                        className='py-1 mr-2 img-fluid'
                        src={heartTeal}
                        alt='...'
                      />
                      <div className='d-flex flex-column'>
                        <p className='headline-3'>Lemak</p>
                        <p className='body-2'>
                          {kebutuhanKal && kebutuhanKal.fat}
                          <span className='body-3 muted'> gram</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <Progress
                        color='secondary-cyan'
                        className={`progress-xs ${s.mutedTeal}`}
                        value='75'
                      />
                    </div>
                  </div>
                </Widget>
              </Col>
              <Col xs={6} sm={6} xl={3}>
                <Widget className='widget-p-sm'>
                  <div className={s.smallWidget}>
                    <div className='d-flex mb-4'>
                      <img
                        className='py-1 mr-2 img-fluid'
                        src={heartViolet}
                        alt='...'
                      />
                      <div className='d-flex flex-column'>
                        <p className='headline-3'>Karbo</p>
                        <p className='body-2'>
                          {kebutuhanKal && kebutuhanKal.carbo}
                          <span className='body-3 muted'> gram</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <Progress
                        color='violet'
                        className={`progress-xs ${s.mutedViolet}`}
                        value='75'
                      />
                    </div>
                  </div>
                </Widget>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col className='mt-4 mt-lg-0 pl-grid-col' xs={12} lg={4}>
          <Widget className='widget-p-lg'>
            <div className='d-flex'>
              <img
                className={s.image}
                src={'https://mamaboi.rotendaokab.go.id/mamaboi/baby.png'}
                style={{ borderRadius: '50%' }}
                alt='...'
              />
              <div className={s.userInfo}>
                <FormGroup row>
                  <Col md='8'>
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color='primary'>
                        {simpleDropdownValue}
                      </DropdownToggle>

                      <DropdownMenu>
                        {anak &&
                          anak.map((item) => (
                            <DropdownItem onClick={changeSelectDropdownSimple}>
                              {item.nama}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                </FormGroup>

                <p className='body-3 muted'>
                  {currentAnak ? currentAnak.gender : 'belum ada data'}
                </p>
              </div>
            </div>
            <div className={s.userParams}>
              <div className='d-flex flex-column'>
                <p className='headline-3'>
                  {currentAnak
                    ? parseFloat(currentAnak.berat).toFixed(0)
                    : ' - '}{' '}
                  kg
                </p>
                <p className='body-3 muted'>Berat</p>
              </div>
              <div className='d-flex flex-column'>
                <p className='headline-3'>
                  {' '}
                  {currentAnak
                    ? parseFloat(currentAnak.tinggi).toFixed(0)
                    : ' - '}{' '}
                  cm
                </p>
                <p className='body-3 muted'>Tinggi</p>
              </div>
              <div className='d-flex flex-column'>
                <p className='headline-3'>{umur ? umur : ' - '} b.</p>
                <p className='body-3 muted'>Umur</p>
              </div>
            </div>
            <div className={s.goals}>
              <div className={s.goalsTitle}>
                <p className='headline-3'>Konsumsi ({konsumsiKal&&konsumsiKal.jumlah_hari} hari)</p>
                
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Kalori </p>
                  <p className='body-2'>
                    {konsumsiKal ? konsumsiKal.total_energy.toFixed(0) : 0}
                    <span className='body-3 muted'>
                      /{kebutuhanKal&&konsumsiKal ? (kebutuhanKal.kalori * konsumsiKal.jumlah_hari).toFixed(0) : 0}
                    </span>
                  </p>
                </div>
                <Progress
                  color='primary'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_energy /
                            (kebutuhanKal.kalori * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Protein</p>
                  <p className='body-2'>
                    {konsumsiKal ? konsumsiKal.total_protein.toFixed(0) : 0}
                    <span className='body-3 muted'>
                      /
                      {kebutuhanKal&&konsumsiKal ? (kebutuhanKal.protein * konsumsiKal.jumlah_hari).toFixed(0) : 0}
                    </span>
                  </p>
                </div>
                <Progress
                  color='secondary-red'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_protein /
                            (kebutuhanKal.protein * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Lemak</p>
                  <p className='body-2'>
                    {konsumsiKal ? konsumsiKal.total_lemak.toFixed(0) : 0}
                    <span className='body-3 muted'>
                      /{kebutuhanKal&&konsumsiKal ? (kebutuhanKal.fat * konsumsiKal.jumlah_hari).toFixed(0) : 0}
                    </span>
                  </p>
                </div>
                <Progress
                  color='secondary-yellow'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_lemak / (kebutuhanKal.fat * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
              <div className='d-flex flex-column mt-3'>
                <div className={s.activity}>
                  <p className='body-2'>Karbohidrat</p>
                  <p className='body-2'>
                    {konsumsiKal ? konsumsiKal.total_karbo.toFixed(0) : 0}
                    <span className='body-3 muted'>
                      /{kebutuhanKal&&konsumsiKal ? (kebutuhanKal.carbo * konsumsiKal.jumlah_hari).toFixed(0) : 0}
                    </span>
                  </p>
                </div>
                <Progress
                  color='secondary-cyan'
                  className='progress-xs'
                  value={
                    konsumsiKal && kebutuhanKal
                      ? (
                          (konsumsiKal.total_karbo / (kebutuhanKal.carbo * konsumsiKal.jumlah_hari)) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </div>
            </div>
            <p className='headline-3'>Konseling</p>
            <div className={`mt-3 ${s.widgetBlock}`}>
              <div className={s.widgetBody}>
                <div className='d-flex'>
                  <img className='img-fluid mr-2' src={gymIcon} alt='...' />
                  <div className='d-flex flex-column'>
                    <p className='body-2'>02.11 , 12:00 - 13:00</p>
                    <p className='body-3 muted'>Ahli gizi</p>
                  </div>
                </div>
                <div className='checkbox checkbox-primary'>
                  <input
                    id='checkbox0'
                    type='checkbox'
                    className='styled'
                    checked={checkboxes[0]}
                    onChange={(event) => toggleCheckbox(0)}
                  />
                  <label htmlFor='checkbox0' />
                </div>
              </div>
            </div>
            <div className={`mt-3 ${s.widgetBlock}`}>
              <div className={s.widgetBody}>
                <div className='d-flex'>
                  <img className='img-fluid mr-2' src={therapyIcon} alt='...' />
                  <div className='d-flex flex-column'>
                    <p className='body-2'>03.11 , 16:00 - 17:30</p>
                    <p className='body-3 muted'>Dokter anak</p>
                  </div>
                </div>
                <div className='checkbox checkbox-primary'>
                  <input
                    id='checkbox1'
                    type='checkbox'
                    className='styled'
                    checked={checkboxes[1]}
                    onChange={(event) => toggleCheckbox(1)}
                  />
                  <label htmlFor='checkbox1' />
                </div>
              </div>
            </div>
            <a
              className={`btn-secondary-red ${s.statsBtn}`}
              href='#top'
              role='button'
            >
              <div style={{ marginRight: 10 }}>
                <i className='eva eva-phone' />
              </div>
              <div>
                <p className='headline-2'>KONSULTASI</p>
                <p className='body-3'>Kirim pertayaan konsultasi</p>
              </div>
            </a>
          </Widget>
        </Col>
      </Row>
    </div>
  );
}
